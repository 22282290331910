import {
    Box,
    Card,
    CardContent,
    Paper,
    Rating,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import BookInfoCard from "shared/components/bookInfoCard";
import {toastMessage} from "shared/components/toast";
import {getBookDetailsService, getBookPagesService} from "shared/services/general";
import styles from "./style.module.scss";
import {useSelector} from "react-redux";
import {getRecentHistoryBookReviewer} from "shared/services/bookReviewerService";
import {
    approveBookAudioService,
    approveBookService,
    changeBookAudioStatusService,
    changeBookStatusService,
    getBookHistoryService, publishBookService, sendBackRevisionService
} from "shared/services/hodService";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import PaginationComponent from "shared/components/pagination";
import SkeletonLoader from "./skeletonLoader";
// import { LastPage } from "@mui/icons-material";
import {Icons} from "assets";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import {routeConstant} from "shared/routes/routesConstant";
import {rolesRoutes} from "shared/routes/allRoutes";
import {getRevisionHistoryByProductManager} from "shared/services/productManagerService";
import {bookStatusEnum, roles} from "shared/utils/constants";
import {number} from "yup";
import CustomButton from "../../shared/components/customButton";
import RejectBook from "../../shared/modals/rejectBook";
import RejectBookAudio from "../../shared/modals/rejectBookAudio";
import classNames from "classnames";
import Confirmation from "../../shared/modals/confirmation";

function BookAudioRevisions() {
    const {id} = useParams<{ id: string }>() as { id: string };
    const {user} = useSelector((state: any) => state.root);
    const [bookDetail, setBookDetail] = useState<any>(null);
    const [bookPages, setBookPages] = useState<any>(null);
    const [bookDetailLoader, setBookDetailLoader] = useState<boolean>(false);
    const [history, setHistory] = useState<any>(null);
    const [totalPage, setTotalPage] = useState<number>(1);
    const [activePage, setActivePage] = useState<number>(1);
    const [loader, setLoader] = useState<boolean>(false);
    const [openReject, setOpenReject] = useState<boolean>(false);
    const [openConfirm, setOpenConfirm] = useState<boolean>(false);
    const [confirmCheck, setConfirmCheck] = useState<any>(null);
    const navigate = useNavigate();


    const getBookHistory = () => {
        setLoader(true);

        let service =
            Number(user?.role?.role_id) === roles.bookReviewer
                ? getRecentHistoryBookReviewer
                : Number(user?.role?.role_id) === roles.productManager
                    ? getRevisionHistoryByProductManager
                    : getBookHistoryService;

        service(bookDetail ? bookDetail?.id : null, activePage)
            .then(({data}) => {
                setHistory(data?.data?.data);
                setTotalPage(data?.data?.last_page);
                setLoader(false);
            })
            .catch((error) => {
                toastMessage("error", error?.response?.data?.message);
                console.log("error", error?.response?.data?.message);
                setLoader(false);
            });
    };

    useEffect(() => {
        if (bookDetail) {
            getBookHistory();
            getBookPagesService(bookDetail.code)
                .then(({data}) => {
                    setBookPages(data?.data?.pages);
                    setBookDetailLoader(false);
                })
                .catch((err) => {
                    toastMessage("error", err?.response?.data?.message);
                    console.log("err", err?.response?.data?.message);
                    setBookDetailLoader(false);
                });
            if(bookDetail.audio_status === 0){
                changeStatus();
            }
        }
        // eslint-disable-next-line
    }, [bookDetail, activePage]);

    const getBookDetails = () => {
        setBookDetailLoader(true);
        getBookDetailsService(id)
            .then(({data}) => {
                setBookDetail(data?.data);
                setBookDetailLoader(false);
            })
            .catch((err) => {
                toastMessage("error", err?.response?.data?.message);
                console.log("err", err?.response?.data?.message);
                setBookDetailLoader(false);
            });
    };

    useEffect(() => {
        getBookDetails();
        // eslint-disable-next-line
    }, [id]);



    const changeStatus = () => {
        changeBookAudioStatusService({ book_id: id, status: 1 })
            .then(({ data }) => {})
            .catch((err) => {
                toastMessage("error", err?.response?.data?.message);
                console.log("err", err?.response?.data?.message);
            });
    };

    useBreadcrumbs(
        {
            title: "Book Audio Revision",
            path: routeConstant.bookAudioRevisions.path.replace(":id", id),
        },
        true
    );

    const handleRejection = () => {
            setOpenReject(true);
    };


    const handleConfirmOpen = (label: string, check: string) => {
            setOpenConfirm(true);
            setConfirmCheck({
                label: label,
                check: check,
            });
    };


    const handleConfirmClose = () => {
        setOpenConfirm(false);
        setConfirmCheck(null);
    };
    const handleActions = () => {
        let resp: any = {
            book_id: bookDetail?.id,
        };


        const service =
            confirmCheck?.check === "approve"
                ? approveBookAudioService
                : confirmCheck?.check === "revision"
                    ? sendBackRevisionService
                    : confirmCheck?.check === "publish"
                        ? publishBookService
                        : null;

        service?.(resp)
            .then(({ data }) => {
                toastMessage("success", data?.message);
                navigate(routeConstant.dashboard.path);
            })
            .catch((err) => {
                toastMessage("error", err?.response?.data?.message);
                console.log("err", err?.response?.data?.message);
            });
    };

    return (
        <div>
            <Card>
                <CardContent>
                    <BookInfoCard book={bookDetail} loader={bookDetailLoader}/>

                    {bookPages && bookPages.map((page: any, index: number) => {
                        return <AudioComponent page={page} book={bookDetail} pageNumber={index + 1} key={index}/>
                    })}
                    {(bookDetail?.audio_status === bookStatusEnum.pending || bookDetail?.audio_status === bookStatusEnum.admin_review) && (
                    <div className={styles.btnContainer}>
                        <CustomButton
                            text="Reject"
                            buttonStyle={styles.btnCustom}
                            handleClick={
                                () => handleRejection()}
                        />
                        <CustomButton
                            text="Approve"
                            buttonStyle={classNames(
                                styles.btnCustom,
                                styles.approveBtn
                            )}
                            handleClick={() =>
                                handleConfirmOpen(
                                    "Are you sure, you want to approve?",
                                    "approve"
                                )
                            }
                        />
                    </div>
                    )}
                </CardContent>
            </Card>

            <RejectBookAudio
                open={openReject}
                handleClose={() => setOpenReject(false)}
                selectedBook={bookDetail}
                // feedback={formData}
                isRating
            />

            <Confirmation
                open={openConfirm}
                handleClose={handleConfirmClose}
                text={confirmCheck?.label}
                handleClick={handleActions}
            />
        </div>
    );
}

interface AudioComponentProps {
    page: any,
    book: any,
    pageNumber: number
}

const AudioComponent = ({page, book, pageNumber}: AudioComponentProps) => {
    const [audioText, setAudioText] = useState(page.audio_text);
    const [audioUrl, setAudioUrl] = useState(page.audio_path);
    const [isSpeaking, setIsSpeaking] = useState(false);

    const handleGenerateSpeech = () => {
        if (window.speechSynthesis.speaking) {
            window.speechSynthesis.cancel(); // Stop speech
            setIsSpeaking(false);
        } else {
            const utterance = new SpeechSynthesisUtterance(audioText);
            utterance.onend = () => setIsSpeaking(false); // Reset state when speech ends
            window.speechSynthesis.speak(utterance);
            setIsSpeaking(true);
        }
    };
    return (
        <div className="d-flex p-4" style={{
            borderTop: '1px solid #E9ECEF',
            backgroundColor: '#F2F7FB',
            borderRadius: '0',
            display: 'flex',
            alignItems: 'center',
            padding: '10px'
        }}>
            <div className="flex-grow-1" style={{flexGrow: 1}}>
                <div className="pb-2" style={{paddingBottom: '10px'}}>Page {pageNumber}</div>
                <div className="d-flex gap-3"
                     style={{width: '100%', display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'center'}}>
                    <div
                        style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: '1rem'}}>
                        <img  style={{ flexGrow: 1}}
                            src={page.page_path}
                            height="70"/>

                        {book?.audio_type === 'ai' && (
                        <CustomButton
                            text={isSpeaking ? "Stop Audio" : "Play Audio"}
                            buttonStyle={styles.btnPlayCustom}
                            handleClick={
                                () => handleGenerateSpeech()}
                        />
                            )}
                    </div>
                    <div className="flex-grow-1" style={{width: '100%', flexGrow: 1}}>
                        {book?.audio_type === 'manual' ? (
                            <div className="audio-preview mt-4">
                                <audio controls style={{width: '100%'}}
                                       src={audioUrl} className="w-100">Your browser does not support the audio element.
                                </audio>
                            </div>
                          ) : (<>
              <textarea style={{width: '100%'}}
                  className={styles.textarea}
                  id="description"
                  name="description"
                  value={audioText}
                  disabled={true}
              />

                        </>)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookAudioRevisions;
