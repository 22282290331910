import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "../utils/interceptor";

const getBookReviewersBooksService = (
  search: string,
  status: number,
  activePage: number,
  itemsPerPage: number
) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (status || status === 0) params.append("status", status.toString());
  if (activePage) params.append("page", activePage.toString());
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());

  const url = `${Endpoint.book_reviewer.getBooks}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getSeriesReviewersBooksService = (
  search: string,
  status: number,
  activePage: number,
  itemsPerPage: number
) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (status || status === 0) params.append("status", status.toString());
  if (activePage) params.append("page", activePage.toString());
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());

  const url = `${Endpoint.series_reviewer.getSeries}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getBookReviewerStats = () => {
  return HTTP_CLIENT.get(Endpoint.book_reviewer.getStats);
};

const getRecentHistoryBookReviewer = (id: any) => {
  return HTTP_CLIENT.get(Endpoint.book_reviewer.recentHistory + id);
};

const getRecentRevisionByReviewer = (id: any) => {
  return HTTP_CLIENT.get(Endpoint.book_reviewer.recentRevision + id);
};

const getRecentSeriesRevisionByReviewer = (id: any) => {
  return HTTP_CLIENT.get(Endpoint.series_reviewer.recentRevision + id);
};

const assignHODSerivce = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.book_reviewer.assignBackToHOD, params);
};

const assignHODSeriesSerivce = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.series_reviewer.assignBackToHOD, params);
};

export {
  getBookReviewersBooksService,
  getSeriesReviewersBooksService,
  getBookReviewerStats,
  getRecentHistoryBookReviewer,
  assignHODSerivce,
  assignHODSeriesSerivce,
  getRecentRevisionByReviewer,
  getRecentSeriesRevisionByReviewer,
};
