import React, { useEffect, useState, ChangeEvent } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { Images } from "assets";
import styles from "./styles.module.scss";
import { Avatar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface AutoCompleteUsersProps {
  userList: any;
  setSearchText: (text: string) => void;
  searchText: string;
  selectedUser: any;
  setSelectedUser: (user: null | any) => void;
  user?: any;
}

export default function AutoCompleteUsers({
  userList,
  setSearchText,
  searchText,
  selectedUser,
  setSelectedUser,
  user,
}: AutoCompleteUsersProps) {
  const [allUsers, setAllUsers] = useState<any>(userList ? userList : []);

  useEffect(() => {
    setAllUsers(userList);
  }, [userList]);

  const handleInputChange = (value: any | null) => {
    setSelectedUser(value);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  console.log("user", user);

  return (
    <Stack spacing={2}>
      {selectedUser ? (
        <div
          className={`${styles.displayAlign} ${styles.selectedNameAutoComplete}`}
        >
          <div className={styles.displayAlign}>
            <Avatar
              src={
                selectedUser
                  ? selectedUser?.profile_picture
                  : Images.UserPlaceholderImage
              }
              style={{
                marginRight: "10px",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
              }}
            />
            <p>
              {selectedUser.full_name
                ? selectedUser.full_name
                : selectedUser?.school_name}
            </p>
          </div>
          {!user && (
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedUser(null);
                setSearchText("");
              }}
            />
          )}
        </div>
      ) : (
        <Autocomplete
          autoFocus
          defaultValue={selectedUser}
          id="free-solo-demo"
          freeSolo
          options={allUsers}
          getOptionLabel={(option: any) => {
            return option?.full_name ? option?.full_name : option?.school_name;
          }}
          onChange={(_, newValue: any) => {
            handleInputChange(newValue);
          }}
          renderOption={(props, option) => {
            return (
              <li {...props}>
                <Avatar
                  src={
                    option?.profile_picture
                      ? option?.profile_picture
                      : Images.UserPlaceholderImage
                  }
                  style={{
                    marginRight: "10px",
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                  }}
                />
                {option?.full_name ? option?.full_name : option?.school_name}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select User"
              value={searchText}
              onChange={(e: any) => handleSearch(e)}
              className={styles.input}
            />
          )}
        />
      )}
    </Stack>
  );
}
