import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "../utils/interceptor";

const getDashboardData = () => {
  return HTTP_CLIENT.get(Endpoint.hod.getStats);
};

const getBooksService = (search: string, status: number) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (status || status === 0) params.append("status", status.toString());

  const url = `${Endpoint.hod.getBooks}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getHODBookReviewerStats = () => {
  return HTTP_CLIENT.get(Endpoint.hod.bookReviewers);
};

const getHODBookReviewersService = (
  search: string,
  activePage?: number,
  itemsPerPage?: number
) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (activePage) params.append("page", activePage.toString());
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());
  const url = `${Endpoint.hod.getAllBookReviewers}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getAllBooksService = (
  search: string,
  status: number,
  activePage: number,
  itemsPerPage: number,
  audioStatus = -1
) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (status >= 0 && (status || status === 0)) params.append("status", status.toString());
  if (audioStatus >= 0) params.append("audio_status", audioStatus.toString());
  if (activePage) params.append("page", activePage.toString());
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());
  const url = `${Endpoint.hod.getBooks}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getAllSeriesService = (
  search: string,
  status: number,
  activePage: number,
  itemsPerPage: number
) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (status || status === 0) params.append("status", status.toString());
  if (activePage) params.append("page", activePage.toString());
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());
  const url = `${Endpoint.hod.getSeries}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const changeBookStatusService = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.hod.changeStatusBook, params);
};

const changeBookAudioStatusService = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.hod.changeStatusBookAudio, params);
};

const changeSeriesStatusService = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.hod.changeStatusSeries, params);
};

const assignReviewerService = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.hod.assignReviewer, params);
};

const seriesAssignReviewerService = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.hod.seriesAssignReviewer, params);
};

const approveBookService = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.hod.approveBooks, params);
};

const approveBookAudioService = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.hod.approveBooksAudio, params);
};

const approveSeriesService = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.hod.approveSeries, params);
};

const publishBookService = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.hod.publishBook, params);
};

const rejectBookService = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.hod.rejectBook, params);
};

const rejectBookAudioService = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.hod.rejectBookAudio, params);
};

const rejectSeriesService = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.hod.rejectSeries, params);
};

const sendBackRevisionService = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.hod.sendBackRevision, params);
};

const sendBackSeriesRevisionService = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.hod.sendBackSeriesRevision, params);
};

const getApprovalPendingBooks = (
  search: string,
  activePage?: number,
  itemsPerPage?: number
) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (activePage) params.append("page", activePage.toString());
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());
  const url = `${Endpoint.hod.approvalPendingBooks}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const recentRevisionByHOD = (id: any) => {
  return HTTP_CLIENT.get(Endpoint.hod.recentRevision + id);
};

const recentSeriesRevisionByHOD = (id: any) => {
  return HTTP_CLIENT.get(Endpoint.hod.recentSeriesRevision + id);
};

const getBookReviewerBooksHOD = (
  id: any,
  search: any,
  activePage: any,
  status: any,
  itemsPerPage: number
) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (status) params.append("status", status);
  if (activePage) params.append("page", activePage.toString());
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());
  const url = `${
    Endpoint.hod.getAllBookReviewersBooks + id + "/books"
  }?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getBookHistoryService = (id: any, activePage?: any) => {
  const params = new URLSearchParams();
  if (activePage) params.append("page", activePage.toString());

  const url = `${Endpoint.hod.getBookHistory + id}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getSeriesHistoryService = (id: any, activePage?: any) => {
  const params = new URLSearchParams();
  if (activePage) params.append("page", activePage.toString());

  const url = `${Endpoint.hod.getSeriesHistory + id}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getReviewerProfileByHOD = (id: any) => {
  return HTTP_CLIENT.get(Endpoint.hod.getReviewerProfile + id);
};

export {
  getDashboardData,
  getBooksService,
  getHODBookReviewerStats,
  getHODBookReviewersService,
  getAllBooksService,
  getAllSeriesService,
  changeBookStatusService,
  changeBookAudioStatusService,
  changeSeriesStatusService,
  assignReviewerService,
  seriesAssignReviewerService,
  approveBookService,
  approveBookAudioService,
  approveSeriesService,
  publishBookService,
  rejectBookService,
  rejectBookAudioService,
  rejectSeriesService,
  sendBackRevisionService,
  sendBackSeriesRevisionService,
  getApprovalPendingBooks,
  recentRevisionByHOD,
  recentSeriesRevisionByHOD,
  getBookReviewerBooksHOD,
  getBookHistoryService,
  getSeriesHistoryService,
  getReviewerProfileByHOD,
};
