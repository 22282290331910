import { Box, Grid, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import CustomButton from "shared/components/customButton";
import { toastMessage } from "shared/components/toast";
import SingleTicketCard from "shared/components/singleTicketCard";
import CreateTicket from "shared/modals/createTicket";
import Confirmation from "shared/modals/confirmation";
import { useLocation, useParams } from "react-router-dom";
import Conversation from "./Conversations";
import {
  closeTicketService,
  getConversationService,
  getTicketsService,
} from "shared/services/adminService";
import { Icons } from "assets";
import Loader from "shared/components/loader/animation";
import { useSelector } from "react-redux";
import {
  closeTicketByProductManager,
  getThreadProductManager,
  getUserTicketsManager,
} from "shared/services/productManagerService";
import { routeConstant } from "shared/routes/routesConstant";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import { roles, user_roles } from "shared/utils/constants";
import ProfileCard from "shared/components/profileCard";

function Ticket() {
  const location = useLocation();
  const params: any = useParams();
  const [allTickets, setAllTickets] = useState<any>([]);
  const [ticketLoader, setTicketLoader] = useState<boolean>(false);
  const [activeTicket, setActiveTicket] = useState<any>(null);
  const [openTicket, setOpenTicket] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [activeConversation, setActiveConversation] = useState<any>([]);
  const [conversationLoader, setConversationLoader] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [createTicketConfirmation, setCreateTicketConfirmation] =
    useState<boolean>(false);
  const {
    user: { user },
  } = useSelector((state: any) => state.root);

  const handleSelectTicket = (item: any) => {
    if (item?.id !== activeTicket?.id) {
      setActiveTicket(item);
    }
  };

  const getTickets = () => {
    setTicketLoader(true);

    // 4 for Product manager and 1 for Admin
    let service =
      Number(user.role) === roles.productManager
        ? getUserTicketsManager
        : getTicketsService;

    service(params?.id)
      .then(({ data }) => {
        setAllTickets(data?.data);
        setActiveTicket(data?.data[0]);
        setTicketLoader(false);
        setCreateTicketConfirmation(false);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
        setTicketLoader(false);
      });
  };

  useEffect(() => {
    if (params?.id) {
      getTickets();
    }
    // eslint-disable-next-line
  }, [params, createTicketConfirmation]);

  const getConversation = () => {
    setConversationLoader(true);

    // 4 for Product manager and 1 for Admin
    let service =
      Number(user.role) === roles?.productManager
        ? getThreadProductManager
        : getConversationService;

    service(activeTicket?.id)
      .then(({ data }) => {
        setActiveConversation(data?.data);
        setConversationLoader(false);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
        setConversationLoader(false);
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  useEffect(() => {
    if (activeTicket) {
      getConversation();
    }
    // eslint-disable-next-line
  }, [activeTicket]);

  const handleCloseTicket = () => {
    setLoader(true);

    // 4 for Product manager and 1 for Admin
    let service =
      Number(user.role) === Number(roles?.productManager)
        ? closeTicketByProductManager
        : closeTicketService;

    service(activeTicket?.id)
      .then(({ data }) => {
        setLoader(false);
        if (data?.statusCode === 200) {
          toastMessage("success", data?.message);
          getTickets();
        } else {
          toastMessage("error", data?.message);
        }
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    if (location.state) {
      setSelectedUser(location?.state?.user);
    }
  }, [location]);

  useBreadcrumbs(
    {
      title: location?.state?.user
        ? location?.state?.user?.full_name
        : "Tickets",
      path: routeConstant.singleTicket.path.replace(":id", params?.id),
    },
    true,
    params?.id
  );

  return (
    <div>
      {/* SCHOOL PROFILE CARD */}
      {
        <Box sx={{ marginBottom: "1.5rem" }}>
          <ProfileCard
            isLoader={ticketLoader}
            isSchool={
              selectedUser
                ? Object?.keys(selectedUser)?.includes("school")
                : false
            }
            isAuthor={
              selectedUser
                ? Object?.keys(selectedUser)?.includes("author")
                : false
            }
            isPublisher={
              selectedUser
                ? Object?.keys(selectedUser)?.includes("publisher")
                : false
            }
            user={selectedUser}
            isParent={
              selectedUser
                ? Object?.keys(selectedUser)?.includes("parent")
                : false
            }
            isReader={
              selectedUser
                ? Object?.keys(selectedUser)?.includes("reader")
                : false
            }
          />
        </Box>
      }

      {!activeTicket && !ticketLoader && (
        <Box className={styles.noDataContainer}>
          <div>
            <Icons.NoDataIcon />
            <div className={styles.ticketHeading}>No ticket so far</div>
          </div>
          <CustomButton
            text={"Add New"}
            buttonStyle={styles.btnNoData}
            handleClick={() => setOpenTicket(true)}
          />
        </Box>
      )}

      {allTickets.length > 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <div className={allTickets?.length > 2 ? styles.ticketListing : ""}>
              {allTickets.length ? (
                <CustomButton
                  text={"Add New"}
                  buttonStyle={styles.btn}
                  handleClick={() => setOpenTicket(true)}
                />
              ) : null}
              {ticketLoader
                ? Array.from({ length: 3 }).map((_, index) => (
                    <div>
                      <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={"100px"}
                        sx={{ borderRadius: "16px", marginBottom: "20px" }}
                      />
                    </div>
                  ))
                : allTickets?.map((item: any) => {
                    return (
                      <SingleTicketCard
                        item={item}
                        handleSelectTicket={handleSelectTicket}
                        activeTicket={activeTicket}
                      />
                    );
                  })}
            </div>
          </Grid>
          <Grid item xs={12} lg={8}>
            <div className={styles.gridContain}>
              {ticketLoader ? (
                <>
                  {/* <div>
                  <Skeleton
                    variant="rectangular"
                    width={"60px"}
                    height={"20px"}
                    sx={{
                      marginTop: "20px",
                      borderRadius: "26px",
                      padding: "10px 25px",
                    }}
                  />
                </div> */}
                  {/* <div>
                  <Skeleton
                    variant="rectangular"
                    width={"60px"}
                    height={"20px"}
                    sx={{
                      marginTop: "20px",
                      borderRadius: "26px",
                      padding: "10px 25px",
                    }}
                  />
                </div> */}
                </>
              ) : (
                <>
                  {user.role !== "4" && activeTicket && (
                    <>
                      {/* <button
                      className={styles.deleteBtn}
                      onClick={() => setOpenConfirm(true)}
                    >
                      <DeleteOutlineIcon />
                      <span>Delete</span>
                    </button> */}
                    </>
                  )}
                </>
              )}
            </div>

            {ticketLoader ? (
              <div>
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={"65vh"}
                  sx={{ marginTop: "20px", borderRadius: "26px" }}
                />
              </div>
            ) : (
              activeTicket && (
                <Conversation
                  activeTicket={activeTicket}
                  loader={conversationLoader}
                  activeConversation={activeConversation}
                  setActiveConversation={setActiveConversation}
                  handleCloseTicket={handleCloseTicket}
                />
              )
            )}
          </Grid>
        </Grid>
      )}
      {loader && <Loader />}

      <CreateTicket
        open={openTicket}
        handleClose={() => setOpenTicket(false)}
        user={selectedUser}
        setConfirmTicket={setCreateTicketConfirmation}
        isSupportOnly={true}
      />
      <Confirmation
        text="Are you sure, you want to delete this ticket?"
        open={openConfirm}
        handleClose={() => setOpenConfirm(false)}
      />
    </div>
  );
}

export default Ticket;
