import React, { Fragment } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import styles from "./style.module.scss";
import { Icons } from "assets";
import { Avatar, Box, Skeleton } from "@mui/material";
import CustomButton from "../customButton";
import { useNavigate } from "react-router-dom";
import { routeConstant } from "shared/routes/routesConstant";
import SchoolIcon from "@mui/icons-material/School";
import AccountBoxIcon from "@mui/icons-material/AccountBox";

interface ProfileCardProps {
  user: any;
  isBookReviewer: boolean;
  isMOU: boolean;
  isSchool: boolean;
  isAuthor: boolean;
  isPublisher: boolean;
  isLoader: boolean;
  isParent?: boolean;
  isReader?: boolean;
}

function ProfileCard({
  user,
  isBookReviewer,
  isPublisher,
  isMOU,
  isSchool,
  isAuthor,
  isLoader,
  isParent,
  isReader,
}: Partial<ProfileCardProps>) {
  const navigate = useNavigate();

  const MOU_Status = [
    { label: "Draft", value: 0 },
    { label: "Review", value: 1 },
    { label: "Pending", value: 2 },
    { label: "Accepted", value: 3 },
    { label: "Declined", value: 4 },
  ];

  const USER_Status = [
    { label: "Pending", value: 0 },
    { label: "Approved", value: 1 },
    { label: "Rejected", value: 2 },
  ];

  return (
    <Card className={styles.cardContainer}>
      <CardContent className={styles.cardContentWrapper}>
        <Box className={styles.upperContainer}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box display={"flex"} alignItems={"center"}>
              {!isLoader ? (
                <Avatar
                  alt={user?.full_name}
                  src={
                    isPublisher
                      ? user?.publisher?.profile_picture
                      : isAuthor
                      ? user?.author?.profile_picture
                      : isSchool
                      ? user?.school?.profile_picture
                      : isParent
                      ? user?.parent?.profile_picture
                      : isReader
                      ? user?.reader?.profile_picture
                      : user?.profile_picture
                  }
                />
              ) : (
                <Skeleton variant="circular" width={40} height={40} />
              )}
            </Box>
            <Box paddingLeft={"16px"}>
              {isLoader ? (
                <Skeleton variant="rectangular" width={150} height={25} />
              ) : (
                <div className={styles.nameLabel}>
                  {isPublisher
                    ? user?.publisher?.full_name
                    : isAuthor
                    ? user?.author?.full_name
                    : isSchool
                    ? user?.school?.school_name
                    : isParent
                    ? user?.parent?.full_name
                    : isReader
                    ? user?.reader?.full_name
                    : user
                    ? user?.full_name
                    : "Not Provided"}
                </div>
              )}
              {isLoader && !isBookReviewer ? (
                <Skeleton
                  variant="rectangular"
                  width={100}
                  height={25}
                  sx={{ marginTop: "5px" }}
                />
              ) : (
                <div
                  className={`${styles.gridContainer} ${styles.sectionsLabel}`}
                >
                  {!isBookReviewer ? (
                    <>
                      {user?.author?.address ||
                      user?.publisher?.address ||
                      user?.school?.first_name ? (
                        <>
                          <span className={styles.addressIconWrapper}>
                            {isSchool ? null : <Icons.LocationIcon />}
                          </span>
                          <span
                            className={isSchool ? styles.username : ""}
                            style={{ marginLeft: "5px" }}
                          >
                            {isPublisher
                              ? user?.publisher?.address
                              : isAuthor
                              ? user?.author?.address
                              : isSchool
                              ? `${user?.school?.first_name} ${user?.school?.last_name}`
                              : "Not Provided"}
                          </span>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </div>
              )}
            </Box>
          </Box>
          {/* {!isBookReviewer && !isMOU && (
            <CustomButton
              text={"MOU"}
              buttonStyle={styles.mouBtn}
              handleClick={() =>
                navigate(routeConstant.mou.path.replace(":id", user?.id))
              }
            />
          )} */}
        </Box>

        <Box>
          <ul className={styles.listing}>
            {isSchool ? (
              <>
                {!isLoader ? (
                  <li className={styles.statusWrapper}>
                    <span className={styles.status}>Status</span>
                    <span
                      className={
                        user?.status === 0
                          ? `${styles.statusValue} ${styles.pending}`
                          : user?.status === 1
                          ? `${styles.statusValue} ${styles.active}`
                          : `${styles.statusValue} ${styles.rejected}`
                      }
                    >
                      {user?.status === 0
                        ? "Pending"
                        : user?.status === 1
                        ? "Approved"
                        : "Rejected"}
                    </span>
                  </li>
                ) : (
                  <Skeleton
                    sx={{ marginRight: "1rem" }}
                    variant="rectangular"
                    width={70}
                    height={30}
                  ></Skeleton>
                )}

                {isPublisher || isAuthor ? <li>Hello</li> : null}

                {/* <li>
                  <AccountBoxIcon />
                  <span>30 teachers</span>
                </li>
                <li>
                  <SchoolIcon />
                  <span>120 students</span>
                </li>
                <li>
                  <Icons.HomeIcon />
                  <span>10 Classrooms</span>
                </li> */}
              </>
            ) : (
              <>
                {!isBookReviewer && (
                  <>
                    {isLoader ? (
                      <Skeleton
                        sx={{ marginRight: "1rem" }}
                        variant="rectangular"
                        width={70}
                        height={30}
                      ></Skeleton>
                    ) : (
                      <>
                        {user?.publisher?.website || user?.author?.website ? (
                          <li
                            onClick={() => {
                              window.open(
                                "https://" +
                                  (isPublisher
                                    ? user?.publisher?.website
                                    : isAuthor
                                    ? user?.author?.website
                                    : null),
                                "_blank"
                              );
                            }}
                          >
                            <Icons.GlobeIcon />
                            <span style={{ cursor: "pointer" }}>
                              {isPublisher
                                ? user?.publisher?.website
                                : isAuthor
                                ? user?.author?.website
                                : null}
                            </span>
                          </li>
                        ) : null}
                      </>
                    )}
                    {isPublisher && (
                      <>
                        {isLoader ? (
                          <Skeleton
                            sx={{ marginRight: "1rem" }}
                            variant="rectangular"
                            width={70}
                            height={30}
                          />
                        ) : (
                          <>
                            <li>
                              <Icons.HomeIcon />
                              <span>
                                {isPublisher
                                  ? user?.publisher?.publishing_house
                                  : "Not Provided"}
                              </span>
                            </li>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}

                {isLoader ? (
                  <Skeleton
                    sx={{ marginRight: "1rem" }}
                    variant="rectangular"
                    width={70}
                    height={30}
                  ></Skeleton>
                ) : (
                  (isPublisher || isAuthor) && (
                    <li>
                      <Icons.BookIcon />
                      <span>
                        {isPublisher
                          ? user?.total_books
                          : isAuthor
                          ? user?.author?.total_published_title
                          : isBookReviewer
                          ? user?.books_count?.assigned
                          : ""}{" "}
                        Books
                      </span>
                    </li>
                  )
                )}
              </>
            )}
            {isLoader ? (
              <Skeleton
                sx={{ marginRight: "1rem" }}
                variant="rectangular"
                width={70}
                height={30}
              ></Skeleton>
            ) : (
              <li>
                <Icons.PhoneIcon />
                <span>
                  {isPublisher
                    ? user?.publisher?.phone
                    : isAuthor
                    ? user?.author?.phone
                    : isSchool
                    ? user?.school?.phone
                    : isParent
                    ? user?.parent?.phone
                    : isReader
                    ? user?.reader?.phone
                      ? user?.reader?.phone
                      : "Not Provided"
                    : user
                    ? user?.full_name
                    : user?.phone
                    ? user?.phone
                    : "Not Provided"}
                </span>
              </li>
            )}
            {isLoader ? (
              <Skeleton
                sx={{ marginRight: "1rem" }}
                variant="rectangular"
                width={70}
                height={30}
              ></Skeleton>
            ) : (
              <li>
                <Icons.EmailIcon />
                <span>{user?.email}</span>
              </li>
            )}
            {isLoader ? (
              <Skeleton
                sx={{ marginRight: "1rem" }}
                variant="rectangular"
                width={70}
                height={30}
              ></Skeleton>
            ) : isPublisher ? (
              <div className={styles.statuses}>
                <li>
                  <span>MOU Status: </span>
                  <span className={styles.mouStatus}>
                    {user?.latest_mou?.status
                      ? MOU_Status?.find(
                          (mou) => mou.value === user?.latest_mou?.status
                        )?.label
                      : "Not Signed"}
                  </span>
                </li>
                {/* <li>
                  <span>Account Status: </span>
                  <span
                    className={
                      user?.status === 0
                        ? styles.pending
                        : user?.status === 1
                        ? styles.active
                        : styles.rejected
                    }
                  >
                    {user?.status === 0 || user?.status
                      ? USER_Status.find(
                          (status) => status.value === user?.status
                        )?.label
                      : null}
                  </span>
                </li> */}
              </div>
            ) : null}
            {isLoader ? (
              <Skeleton
                sx={{ marginRight: "1rem" }}
                variant="rectangular"
                width={70}
                height={30}
              ></Skeleton>
            ) : isAuthor ? (
              <div className={styles.statuses}>
                <li>
                  <span>MOU:</span>
                  <span className={styles.mouStatus}>
                    {user?.latest_mou?.status
                      ? MOU_Status?.find(
                          (mou) => mou.value === user?.latest_mou?.status
                        )?.label
                      : "Not Signed"}
                  </span>
                </li>
                {/* <li>
                  <span>Account Status:</span>
                  <span
                    className={
                      user?.status === 0
                        ? styles.pending
                        : user?.status === 1
                        ? styles.active
                        : styles.rejected
                    }
                  >
                    {user?.status === 0 || user?.status
                      ? USER_Status.find(
                          (status) => status.value === user?.status
                        )?.label
                      : null}
                  </span>
                </li> */}
              </div>
            ) : null}
          </ul>
        </Box>
      </CardContent>
    </Card>
  );
}

export default ProfileCard;
