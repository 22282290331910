import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Skeleton } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Icons } from "assets";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AutoCompleteUsers from "shared/components/autoCompleteUsers";
import CustomButton from "shared/components/customButton";
import Loader from "shared/components/loader/animation";
import { toastMessage } from "shared/components/toast";
import {
  // addCommentService,
  createTicketAndCommnetServiceByAdmin,
  getTicketUsers,
} from "shared/services/adminService";
import {
  createTicketAndServiceByProductManager,
  getAllUsersProductManager,
} from "shared/services/productManagerService";
import { user_roles } from "shared/utils/constants";
import { createTicketSchema } from "shared/utils/validationSchemas";
import styles from "./style.module.scss";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface createTicket {
  open: boolean;
  handleClose: any;
  user?: any;
  isSchool?: boolean;
  setConfirmTicket?: any;
  getAllTickets?: any;
  isSupportOnly?: boolean;
}

export default function AlertDialogSlide({
  open,
  handleClose,
  user,
  isSchool,
  getAllTickets,
  setConfirmTicket,
  isSupportOnly,
}: createTicket) {
  const localUser = useSelector((state: any) => state.root);
  const [allUsers, setAllUsers] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [userLoader, setUserLoader] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");

  const options = [
    { title: "Support Chat", value: 1 },
    { title: "MOU", value: 2 },
  ];
  const [selectiveOption, setSelectiveOption] = useState<any>(options[0]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openDropDown = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDropDown = () => {
    setAnchorEl(null);
  };

  const handleSelectOption = (item: any) => {
    setSelectiveOption(item);
    handleCloseDropDown();
  };
  const formik: any = useFormik({
    initialValues: {
      title: "",
      message: "",
      selectedUser: null,
    },
    validationSchema: createTicketSchema,
    onSubmit: (values: any) => {
      handleSubmit(values);
    },
  });

  const onClose = () => {
    formik.resetForm();
    handleClose();
    setSelectedUser(null);
  };

  const getAllUsers = () => {
    // 4 for Product manager and 1 for Admin
    let service =
      localUser?.user?.user?.role === "4"
        ? getAllUsersProductManager
        : getTicketUsers;

    service(search, isSchool ? "school" : "partners")
      .then(({ data }) => {
        setAllUsers(data?.data?.data);
        setUserLoader(false);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log(err?.response?.data?.message);
        setUserLoader(false);
      });
  };

  useEffect(() => {
    if (open) {
      getAllUsers();
    }
    // eslint-disable-next-line
  }, [open, search]);

  const handleSubmit = (values: any) => {
    setLoader(true);
    let resp = {
      user_id:
        user?.role === user_roles.school ? user?.id : values?.selectedUser?.id,
      title: values?.title,
      type: selectiveOption?.value,
      comment: values?.message,
    };
    let service =
      localUser?.user?.user?.role === "4"
        ? createTicketAndServiceByProductManager
        : createTicketAndCommnetServiceByAdmin;

    service(resp)
      .then(({ data, status }) => {
        if (status) {
          toastMessage("success", data?.message);
          // setConfirmTicket(true);
          onClose();
          setLoader(false);
          getAllTickets();
        }
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
        setLoader(false);
      });

    // createTicketService(resp)
    //   .then(({ data }) => {
    //     if (data?.statusCode === 200) {
    //       toastMessage("success", data?.message);
    //       setConfirmTicket(true);
    //     } else {
    //       toastMessage("error", data?.message);
    //     }

    //     // if (selectiveOption?.value === options[0].value) {
    //     handleAttachMessage(values?.message, data?.data?.id);
    //     // } else {
    //     // setLoader(false);
    //     // }

    //     onClose();
    //   })
    //   .catch((err) => {
    //     toastMessage("error", err?.response?.data?.message);
    //     console.log("err", err?.response?.data?.message);
    //     setLoader(false);
    //   });
  };

  // const handleAttachMessage = (message: string, id: number) => {
  //   let resp = {
  //     ticket_id: id,
  //     type: 1,
  //     comment: message,
  //   };

  //   addCommentService(resp)
  //     .then(({ data }) => {
  //       setLoader(false);
  //       onClose();
  //     })
  //     .catch((err) => {
  //       toastMessage("error", err?.response?.data?.message);
  //       console.log("err", err?.response?.data?.message);
  //       setLoader(false);
  //     });
  // };

  useEffect(() => {
    if (user) {
      setSearch(
        user?.parent
          ? user?.parent?.full_name
          : user?.reader
          ? user?.reader?.full_name
          : user?.publisher
          ? user?.publisher?.full_name
          : user?.author
          ? user?.author?.full_name
          : user?.full_name
      );
    }
    if (user && Number(user?.role) !== user_roles.school) {
      let select = allUsers?.find((item: any) => item?.id === user?.id);
      setSelectedUser(select);
      formik.setFieldValue("selectedUser", select);
    } else {
      setSelectedUser(user);
      formik.setFieldValue("selectedUser", user);
    }
    // eslint-disable-next-line
  }, [user, open, allUsers]);


  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        fullWidth
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={styles.dialogContent}>
          {/* <Icons.CrossIcon
            className={styles.crossIcon}
            onClick={() => handleClose()}
          /> */}
          <div className={styles.crossIcon}>
            <Icons.CrossIcon onClick={() => onClose()} />
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.title}>Add new ticket</div>

            {Number(user?.role) !== user_roles.school && (
              <>
                <div className={styles.heading}>
                  Select User <span>*</span>
                </div>
                {userLoader ? (
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={45}
                    sx={{ marginTop: "5px", borderRadius: "32px" }}
                  />
                ) : (
                  <AutoCompleteUsers
                    user={user ? user : null}
                    userList={allUsers}
                    setSelectedUser={(user: any) => {
                      formik.setFieldValue("selectedUser", user);
                      setSelectedUser(user);
                    }}
                    selectedUser={selectedUser}
                    searchText={search}
                    setSearchText={setSearch}
                  />
                )}
                {formik.touched.selectedUser || formik?.errors?.selectedUser ? (
                  <div className={styles.errorText}>
                    {formik?.errors?.selectedUser}
                  </div>
                ) : (
                  <div style={{ height: "20px" }}></div>
                )}
              </>
            )}

            <div>
              <div className={styles.heading}>
                Ticket Title <span>*</span>
              </div>
              <input
                className={styles.input}
                placeholder="Enter main title"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.title && formik.errors.title ? (
                <div className={styles.errorText}>{formik?.errors?.title}</div>
              ) : (
                <div style={{ height: "20px" }}></div>
              )}
              {!isSchool &&
                Number(user?.role) !== user_roles.school &&
                !isSupportOnly && (
                  <>
                    <div className={styles.heading}>
                      Ticket Type <span>*</span>
                    </div>
                    <div>
                      <Button
                        id="basic-button"
                        aria-controls={openDropDown ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openDropDown ? "true" : undefined}
                        onClick={handleClick}
                        className={styles.dropdownMenu}
                      >
                        <span>{selectiveOption?.title}</span>
                        <ExpandMoreIcon className={styles.icon} />
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openDropDown}
                        onClose={handleCloseDropDown}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {options?.map((item: any, index: any) => {
                          return (
                            <MenuItem
                              key={index}
                              onClick={() => handleSelectOption(item)}
                            >
                              {item?.title}
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    </div>
                  </>
                )}
              <div className={styles.heading}>
                Message <span>*</span>
              </div>
              <textarea
                name="message"
                className={styles.textarea}
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.message && formik.errors.message ? (
                <div className={styles.errorText}>
                  {formik?.errors?.message}
                </div>
              ) : (
                <div style={{ height: "20px" }}></div>
              )}
              <CustomButton text="Create" />
            </div>
          </form>
        </DialogContent>
        {loader && <Loader />}
      </Dialog>
    </div>
  );
}
