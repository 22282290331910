import {
  Grid,
  Skeleton,
  // useMediaQuery
} from "@mui/material";
import React from "react";
import styles from "./style.module.scss";

interface BookInfoCardProps {
  series?: any;
  loader: boolean;
}

function SeriesInfoCard({ series, loader }: Partial<BookInfoCardProps>) {
  // const gridSizing = useMediaQuery("(max-width:500px)");
  const BOOK_TYPE = [
    { value: 1, text: "Free" },
    { value: 2, text: "Premium" },
  ];

  return (
    <div className={styles.contentContainer}>
      <div className={styles.picContainer}>
        {loader ? (
          <Skeleton variant="rectangular" width={"250px"} height={"100%"} />
        ) : (
          <img
            src={series?.cover_photo}
            alt="book-cover"
            className={styles.book}
          />
        )}
      </div>
      <div className={styles.bookInfo}>
        <Grid container spacing={2}>
          <Grid xs={6} md={4} padding={"10px"}>
            {" "}
            <div className={styles.title}>Series name</div>
            <div className={styles.subTitle}>
              {loader ? (
                <Skeleton variant="rectangular" width={80} height={25} />
              ) : (
                  series?.name
              )}
            </div>{" "}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid xs={6} md={4} padding={"10px"}>
            {" "}
            <div className={styles.title}>Series description</div>
            <div className={styles.subTitle}>
              {loader ? (
                <Skeleton variant="rectangular" width={80} height={25} />
              ) : (
                  series?.description
              )}
            </div>{" "}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default SeriesInfoCard;
