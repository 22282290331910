import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  Skeleton,
  Radio,
  RadioGroup,
  Rating,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import BookInfoCard from "shared/components/bookInfoCard";
import CustomButton from "shared/components/customButton";
// import { enums } from "./constant";
import Section from "shared/components/reviewSection";
import { initialForm, options } from "shared/utils/constants";
import { useNavigate } from "react-router-dom";
import Loader from "shared/components/loader/animation";
import {
  getBookDetailsService,
  getGenresService, getSeriesDetailsService,
} from "shared/services/general";
import { toastMessage } from "shared/components/toast";
import {
  getAllAgeRangesService,
  getKeywordsService,
} from "shared/services/general";
import { useParams } from "react-router-dom";
import RejectBook from "shared/modals/rejectBook";
import {
  assignHODSeriesSerivce,
  assignHODSerivce,
  getRecentRevisionByReviewer, getRecentSeriesRevisionByReviewer,
} from "shared/services/bookReviewerService";
import { routeConstant } from "shared/routes/routesConstant";
import { enums } from "./constant";
import { useSelector } from "react-redux";
import {
  approveBookService, approveSeriesService,
  publishBookService,
  recentRevisionByHOD, recentSeriesRevisionByHOD,
  sendBackRevisionService, sendBackSeriesRevisionService,
} from "shared/services/hodService";
import Confirmation from "shared/modals/confirmation";
import AssignReviewer from "shared/modals/assignReviewer";
import ReviewDetails from "shared/components/reviewDetails";
import SendToRevision from "shared/modals/sendForRevision";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import classNames from "classnames";
import SeriesInfoCard from "../../shared/components/seriesInfoCard";
import RejectSeries from "../../shared/modals/rejectSeries";
import SeriesAssignReviewer from "../../shared/modals/seriesAssignReviewer";

function ReviewSeries() {
  const [active, setActive] = useState<any>(null);
  const [formData, setFormData] = useState<any>(initialForm?.intial_series_review);
  const [genreLoader, setGenreLoader] = useState<boolean>(false);
  const [seriesDetail, setSeriesDetail] = useState<any>(null);
  const [seriesDetailLoader, setSeriesDetailLoader] = useState<boolean>(false);
  const [openReject, setOpenReject] = useState<boolean>(false);
  const [initialData, setIntialData] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [historyLoader, setHistoryLoader] = useState<boolean>(false);
  const [rejection, setRejection] = useState<any>(null);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [confirmCheck, setConfirmCheck] = useState<any>(null);
  const [openReviewer, setOpenReviewer] = useState<boolean>(false);
  const [bookRating, setBookRating] = useState<any>(initialForm?.rating.value);
  const [editBool, setEditBool] = useState<boolean>(false);
  const [assginerId, setAssignerId] = useState<any>(null);
  const [openRevision, setOpenRevision] = useState<boolean>(false);
  const {
    user: { user },
  } = useSelector((state: any) => state.root);
  const { id } = useParams<{ id: string }>() as { id: string };
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleOpenRevision = () => {
    if (!handleValidation()) {
      setOpenRevision(true);
    }
  };

  const handleCloseRevision = () => {
    setOpenRevision(false);
  };

  const getSeriesDetails = () => {
    setSeriesDetailLoader(true);
    getSeriesDetailsService(id)
      .then(({ data }) => {
        setSeriesDetail(data?.data);
        setSeriesDetailLoader(false);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
        setSeriesDetailLoader(false);
      });
  };

  useEffect(() => {
    getSeriesDetails();
    // eslint-disable-next-line
  }, [id]);

  const handleApprove = () => {
    if (!handleValidation()) {
      let resp: any = {
        series_id: seriesDetail?.id,
        feedback: {
          rating: { value: bookRating },
          intial_review: initialData,
          detailed_review: formData,
          reject_reason: null,
        },
        comment: "",
      };
      setLoader(true);
      assignHODSeriesSerivce(resp)
        .then(({ data }) => {
          toastMessage("success", data?.message);
          navigate(routeConstant.dashboard.path);
          setLoader(false);
        })
        .catch((err) => {
          toastMessage("error", err?.response?.data?.message);
          console.log("err", err?.response?.data?.message);
          setLoader(false);
        });
    }
  };

  const handleRevision = () => {
    setHistoryLoader(true);

    const service =
      Number(user?.role) === 3
        ? getRecentSeriesRevisionByReviewer
        : recentSeriesRevisionByHOD;

    service(seriesDetail?.id)
      .then(({ data }) => {
        if (Number(user?.role) === 2) {
          if (data?.data?.assigner) {
            setAssignerId(data?.data?.reviewer?.id);
          }

          if (data?.data?.book_review_assignment) {
            setAssignerId(data?.data?.book_review_assignment?.reviewer_id);
          }
        }

        setBookRating(
          data?.data?.data?.rating ? data?.data?.data?.rating?.value : null
        );

        setIntialData(
          data?.data?.data?.intial_review
            ? data?.data?.data?.intial_review
            : null
        );

        if (data?.data?.data?.detailed_review) {
          setFormData(data?.data?.data?.detailed_review);
        }

        setHistoryLoader(false);
        setRejection(
          data?.data?.data?.reject_reason
            ? data?.data?.data?.reject_reason
            : null
        );
        setActive(
          data?.data?.data?.reject_reason.find(
            (item: any) => item?.marked === true
          ).value
        );
      })
      .catch((err) => {
        console.log("err", err?.response?.data?.message);
        setHistoryLoader(false);
      });
  };

  useEffect(() => {
    if (seriesDetail?.id) {
      handleRevision();
    }
    // eslint-disable-next-line
  }, [seriesDetail?.id]);

  const handleChange = (e: any) => {
    setActive(e.target.value);
    setRejection((prev: any) =>
      prev.map((item: any) => ({
        ...item,
        marked: item.value === Number(e.target.value),
      }))
    );

    if (Number(e.target.value) !== enums[enums.length - 1].value) {
      setRejection((prevEnums: any) =>
        prevEnums.map((item: any) => ({
          ...item,
          reason: "",
        }))
      );
    }
  };

  const handleConfirmOpen = (label: string, check: string) => {
    if (!handleValidation()) {
      setOpenConfirm(true);
      setConfirmCheck({
        label: label,
        check: check,
      });
    }
  };

  const handleConfirmClose = () => {
    setOpenConfirm(false);
    setConfirmCheck(null);
  };

  const handleActions = () => {
    let resp: any = {
      series_id: seriesDetail?.id,
      feedback: {
        intial_review: initialData,
        detailed_review: formData,
        reject_book: rejection,
      },
    };

    if (confirmCheck?.check !== "revision") {
      resp["rating"] = bookRating;
    }

    const service =
      confirmCheck?.check === "approve"
        ? approveSeriesService
        : confirmCheck?.check === "revision"
        ? sendBackSeriesRevisionService
        : confirmCheck?.check === "publish"
        ? publishBookService
        : null;

    service?.(resp)
      .then(({ data }) => {
        toastMessage("success", data?.message);
        navigate(routeConstant.dashboard.path);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
      });
  };

  const handleValidation = () => {
    let hasError = false;

    Object.values(formData).forEach((item: any) => {
      if (item.option === 0 && item?.type !== "rating") {
        if (item.type === "checklist") {
          const hasTrueValue = item.options.some(
            (itm: any) => itm.value === true
          );

          if (hasTrueValue) {
            const isSelectedOther = item.options.find(
              (itm: any) => itm.value === true && itm.name === "Others"
            );

            if (isSelectedOther?.comment === "") {
              toastMessage("error", `Please type something in ${item.label}`);
              hasError = true;
            }
          }

          if (!hasTrueValue) {
            toastMessage("error", `Please select something in ${item.label}`);
            hasError = true;
          }
        }

        if (item.type === "dropdown" && item.selected === null) {
          toastMessage("error", `Please select something in ${item.label}`);
          hasError = true;
        }

        if (item.type === "text" && item.comment === "") {
          toastMessage("error", `Please type something in ${item.label}`);
          hasError = true;
        }
      } else if (item?.type === "rating" && item?.value === 0) {
        toastMessage("error", `Please put rating in ${item.label}`);
        hasError = true;
      }
    });

    if (user.role === "3") {
      // 3 is for book reviewer
      // 2 is for hod
      if (bookRating === null || bookRating === 0) {
        toastMessage("error", `Please put rating for books`);
        hasError = true;
      }
    }

    return hasError;
  };

  const handleRejection = () => {
    if (!handleValidation()) {
      setOpenReject(true);
    }
  };

  const handleSendRevision = (form: any) => {
    if (!handleValidation()) {
      let resp: any = {
        series_id: seriesDetail?.id,
        feedback: {
          intial_review: initialData,
          detailed_review: form,
          reject_book: rejection,
          rating: { value: bookRating },
        },
      };

      sendBackSeriesRevisionService(resp)
        .then(({ data }) => {
          toastMessage("success", data?.message);
          navigate(routeConstant.dashboard.path);
        })
        .catch((err) => {
          toastMessage("error", err?.response?.data?.message);
          console.log("err", err?.response?.data?.message);
        });
    }
  };

  useBreadcrumbs(
    {
      title: "Detailed Review",
      path: routeConstant.review.path.replace(":id", id),
    },
    true
  );

  return (
    <Card className={styles.card}>
      <CardContent className={styles.cardContent}>
        <SeriesInfoCard series={seriesDetail} loader={seriesDetailLoader} />

        {historyLoader ? (
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={200}
            sx={{ marginTop: "20px", borderRadius: "10px" }}
          />
        ) : (
          !seriesDetailLoader &&
          (user.role === "2" && !editBool ? (
            <ReviewDetails
              setEditBool={setEditBool}
              data={formData}
              rejection={rejection}
              handleConfirmOpen={handleConfirmOpen}
              setOpenReject={setOpenReject}
            />
          ) : (
            <div>

              <div className={styles.section}>
                <div
                  className={styles.sectionHeading}
                  style={{ fontSize: "20px" }}
                >
                  Design Review
                </div>

                <div className={styles.singleSection}>
                  <div className={styles.sectionHeading}>Rate this series</div>
                  <div style={{ marginTop: "10px" }}>
                    <Rating
                      name="simple-controlled"
                      value={bookRating}
                      onChange={(event, newValue) => {
                        setBookRating(newValue);
                      }}
                      max={10}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.btnContainer}>
                {user?.role === "3" ? (
                  <>
                    {" "}
                    <CustomButton
                      text="Approve"
                      buttonStyle={classNames(
                        styles.btnCustom,
                        styles.approveBtn
                      )}
                      handleClick={() => handleApprove()}
                    />
                    <CustomButton
                      text="Reject"
                      buttonStyle={styles.btnInsideOut}
                      handleClick={() => handleRejection()}
                    />{" "}
                  </>
                ) : (
                  <>
                    <CustomButton
                      text="Approve"
                      buttonStyle={classNames(
                        styles.btnCustom,
                        styles.approveBtn
                      )}
                      handleClick={() =>
                        handleConfirmOpen(
                          "Are you sure, you want to approve?",
                          "approve"
                        )
                      }
                    />
                    {/* <CustomButton
                      text="Publish"
                      buttonStyle={styles.btnCustom}
                      handleClick={() =>
                        handleConfirmOpen(
                          "Are you sure, you want to publish this book?",
                          "publish"
                        )
                      }
                    /> */}
                    <CustomButton
                      text="Reject"
                      buttonStyle={classNames(
                        styles.btnCustom,
                        styles.rejectBtn
                      )}
                      handleClick={() => setOpenReject(true)}
                    />
                    <CustomButton
                      text="Send back for revision"
                      buttonStyle={styles.btnInsideOut}
                      handleClick={() =>
                        // handleConfirmOpen(
                        //   "Are you sure, you want to send this to revision?",
                        //   "revision"
                        // )
                        handleOpenRevision()
                      }
                    />
                    <CustomButton
                      text="Assign back to reviewer"
                      buttonStyle={styles.btnInsideOut}
                      handleClick={() => setOpenReviewer(true)}
                    />
                  </>
                )}
              </div>
            </div>
          ))
        )}
      </CardContent>

      <SendToRevision
        open={openRevision}
        handleClose={handleCloseRevision}
        formData={formData}
        handleSendRevision={handleSendRevision}
      />
      <Confirmation
        open={openConfirm}
        handleClose={handleConfirmClose}
        text={confirmCheck?.label}
        handleClick={handleActions}
      />
      <RejectSeries
        open={openReject}
        handleClose={() => setOpenReject(false)}
        selectedBook={seriesDetail}
        feedback={formData}
        initialData={initialData}
        rejection_data={rejection}
        bookReviewRating={bookRating}
        isFromReview
      />
      <SeriesAssignReviewer
        open={openReviewer}
        handleClose={() => setOpenReviewer(false)}
        detailed_review={formData}
        initialDetail={initialData}
        reject_reason={rejection}
        selectedSeries={seriesDetail}
        assginerId={assginerId}
        bookRating={bookRating}
      />
      {loader && <Loader />}
    </Card>
  );
}

export default ReviewSeries;
