import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EditNoteIcon from "@mui/icons-material/EditNote";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
// import LoyaltyIcon from "@mui/icons-material/Loyalty";
// import SettingsIcon from "@mui/icons-material/Settings";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ArticleIcon from "@mui/icons-material/Article";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import HandshakeIcon from "@mui/icons-material/Handshake";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import PaymentIcon from "@mui/icons-material/Payment";
import RateReviewIcon from "@mui/icons-material/RateReview";
import ReportIcon from "@mui/icons-material/Report";
import SchoolIcon from "@mui/icons-material/School";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import { Icons } from "assets";
import { routeConstant } from "shared/routes/routesConstant";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CampaignIcon from "@mui/icons-material/Campaign";

function getRoutes() {
  // const Routes: any = {
  //   admin: [
  //     {
  //       title: "Dashboard",
  //       path: routeConstant.dashboard.path,
  //       icon: <DashboardIcon />,
  //     },
  //     {
  //       title: "Tickets",
  //       path: routeConstant.tickets.path,
  //       icon: <LocalActivityIcon />,
  //     },
  //     {
  //       title: "Publishers",
  //       icon: <AutoStoriesIcon />,
  //       open: false,
  //       subMenu: [
  //         {
  //           title: "Publishers",
  //           path: routeConstant.publishers.path,
  //           subMenuOf: "Publishers",
  //         },
  //         {
  //           title: "Chats",
  //           path: routeConstant.chat.path,
  //           subMenuOf: "Publishers",
  //         },
  //         {
  //           title: "Books",
  //           path: routeConstant.books.path,
  //           subMenuOf: "Publishers",
  //         },
  //       ],
  //     },
  //     {
  //       title: "Authors",
  //       icon: <EditNoteIcon />,
  //       open: false,
  //       subMenu: [
  //         {
  //           title: "Authors",
  //           path: routeConstant.author.path,
  //           subMenuOf: "Publishers",
  //         },
  //         {
  //           title: "Chats",
  //           path: routeConstant.chat.path,
  //           subMenuOf: "Publishers",
  //         },
  //         {
  //           title: "Books",
  //           path: routeConstant.books.path,
  //           subMenuOf: "Publishers",
  //         },
  //       ],
  //     },
  //     // {
  //     //   title: "Authors",
  //     //   path: routeConstant.author.path,
  //     //   icon: <EditNoteIcon />,
  //     // },
  //     {
  //       title: "Book Reviewers",
  //       path: routeConstant.bookReviewer.path,
  //       icon: <RateReviewIcon />,
  //     },
  //     {
  //       title: "Settings",
  //       path: routeConstant.settings.path,
  //       icon: <SettingsIcon />,
  //     },
  //   ],
  //   hod: [
  //     {
  //       title: "Dashboard",
  //       path: routeConstant.dashboard.path,
  //       icon: <DashboardIcon />,
  //     },
  //     {
  //       title: "Book Reviewers",
  //       path: routeConstant.bookReviewer.path,
  //       icon: <RateReviewIcon />,
  //     },
  //     {
  //       title: "Books",
  //       path: routeConstant.books.path,
  //       icon: <MenuBookIcon />,
  //     },
  //     {
  //       title: "Settings",
  //       path: routeConstant.settings.path,
  //       icon: <SettingsIcon />,
  //     },
  //   ],
  //   bookReviewer: [
  //     {
  //       title: "Dashboard",
  //       path: routeConstant.dashboard.path,
  //       icon: <DashboardIcon />,
  //     },
  //     {
  //       title: "Books",
  //       path: routeConstant.books.path,
  //       icon: <MenuBookIcon />,
  //     },
  //     {
  //       title: "Settings",
  //       path: routeConstant.settings.path,
  //       icon: <SettingsIcon />,
  //     },
  //   ],
  //   accountant: [
  //     {
  //       title: "Dashboard",
  //       path: routeConstant.dashboard.path,
  //       icon: <DashboardIcon />,
  //     },
  //     {
  //       title: "Transactions",
  //       path: routeConstant.transactions.path,
  //       icon: <AccountBalanceIcon />,
  //     },
  //     {
  //       title: "Settings",
  //       path: routeConstant.settings.path,
  //       icon: <SettingsIcon />,
  //     },
  //   ],
  //   manager: [
  //     {
  //       title: "Dashboard",
  //       path: routeConstant.dashboard.path,
  //       icon: <DashboardIcon />,
  //     },
  //     {
  //       title: "Sales of book",
  //       icon: <AutoStoriesIcon />,
  //       open: false,
  //       subMenu: [
  //         {
  //           title: "Soft-copy sales",
  //           path: routeConstant.softCopy.path,
  //           subMenuOf: "Sales of book",
  //         },
  //         {
  //           title: "Hard-copy sales",
  //           path: routeConstant.hardCopy.path,
  //           subMenuOf: "Sales of book",
  //         },
  //       ],
  //     },
  //     {
  //       title: "Books catalog",
  //       path: routeConstant.booksCatalog.path,
  //       icon: <LibraryBooksIcon />,
  //     },
  //     {
  //       title: "Membership",
  //       path: routeConstant.membership.path,
  //       icon: <PeopleAltIcon />,
  //     },
  //     {
  //       title: "Featured books",
  //       path: routeConstant.featuredBooks.path,
  //       icon: <BeenhereIcon />,
  //     },
  //     {
  //       title: "Customer support",
  //       path: routeConstant.customerSupport.path,
  //       icon: <SupportAgentIcon />,
  //     },
  //     {
  //       title: "Reported Books",
  //       path: routeConstant.requiredBooks.path,
  //       icon: <ImportContactsIcon />,
  //     },
  //     {
  //       title: "Settings",
  //       path: routeConstant.settings.path,
  //       icon: <SettingsIcon />,
  //     },
  //   ],
  //   head: [
  //     {
  //       title: "Dashboard",
  //       path: routeConstant.dashboard.path,
  //       icon: <DashboardIcon />,
  //     },
  //     {
  //       title: "MOU",
  //       path: routeConstant.allMou.path,
  //       icon: <ArticleIcon />,
  //     },
  //     {
  //       title: "Transactions",
  //       path: routeConstant.transactions.path,
  //       icon: <AccountBalanceIcon />,
  //     },
  //     {
  //       title: "Best Sellers",
  //       path: routeConstant.bestSellers.path,
  //       icon: <ImportContactsIcon />,
  //     },
  //     {
  //       title: "Reviewers Performace",
  //       path: routeConstant.reviewersPerformance.path,
  //       icon: <AutoGraphIcon />,
  //     },
  //     {
  //       title: "Partners Account",
  //       path: routeConstant.partnerAccount.path,
  //       icon: <HandshakeIcon />,
  //     },
  //     {
  //       title: "Settings",
  //       path: routeConstant.settings.path,
  //       icon: <SettingsIcon />,
  //     },
  //   ],
  // };

  let admin = [
    {
      title: "Dashboard",
      path: routeConstant.dashboard.path,
      icon: <DashboardIcon />,
    },
    {
      title: "Tickets",
      path: routeConstant.tickets.path,
      icon: <LocalActivityIcon />,
    },
    {
      title: "Publishers",
      path: routeConstant.publishers.path,
      icon: <AutoStoriesIcon />,
    },
    {
      title: "Authors",
      path: routeConstant.author.path,
      icon: <EditNoteIcon />,
    },
    // {
    //   title: "Publishers",
    //   icon: <AutoStoriesIcon />,
    //   open: false,
    //   subMenu: [
    //     {
    //       title: "Publishers",
    //       path: routeConstant.publishers.path,
    //       subMenuOf: "Publishers",
    //     },
    //     {
    //       title: "Chats",
    //       path: routeConstant.chat.path,
    //       subMenuOf: "Publishers",
    //     },
    //     {
    //       title: "Books",
    //       path: routeConstant.books.path,
    //       subMenuOf: "Publishers",
    //     },
    //   ],
    // },
    // {
    //   title: "Authors",
    //   icon: <EditNoteIcon />,
    //   open: false,
    //   subMenu: [
    //     {
    //       title: "Authors",
    //       path: routeConstant.author.path,
    //       subMenuOf: "Publishers",
    //     },
    //     {
    //       title: "Chats",
    //       path: routeConstant.chat.path,
    //       subMenuOf: "Publishers",
    //     },
    //     {
    //       title: "Books",
    //       path: routeConstant.books.path,
    //       subMenuOf: "Publishers",
    //     },
    //   ],
    // },
    // {
    //   title: "Authors",
    //   path: routeConstant.author.path,
    //   icon: <EditNoteIcon />,
    // },
    {
      title: "Schools",
      icon: <SchoolIcon />,
      subMenu: [
        {
          title: "School Accounts",
          path: routeConstant.schoolAccounts.path,
          subMenuOf: "School",
        },
        // {
        //   title: "Approval Requests",
        //   path: routeConstant.schoolRequests.path,
        //   subMenuOf: "School",
        // },
        {
          title: "School Tickets",
          path: routeConstant.schoolTickets.path,
          subMenuOf: "School",
        },
      ],
    },
    {
      title: "Book Reviewers",
      path: routeConstant.bookReviewer.path,
      icon: <RateReviewIcon />,
    },
    // {
    //   title: "Settings",
    //   path: routeConstant.settings.path,
    //   icon: <SettingsIcon />,
    // },
  ];
  let hod = [
    {
      title: "Dashboard",
      path: routeConstant.dashboard.path,
      icon: <DashboardIcon />,
    },
    {
      title: "Book Reviewers",
      path: routeConstant.bookReviewer.path,
      icon: <RateReviewIcon />,
    },
    {
      title: "Books",
      path: routeConstant.books.path,
      icon: <MenuBookIcon />,
    },
    {
      title: "Series",
      path: routeConstant.series.path,
      icon: <MenuBookIcon />,
    },
    // {
    //   title: "Settings",
    //   path: routeConstant.settings.path,
    //   icon: <SettingsIcon />,
    // },
  ];
  let bookReviewer = [
    {
      title: "Dashboard",
      path: routeConstant.dashboard.path,
      icon: <DashboardIcon />,
    },
    {
      title: "Books",
      path: routeConstant.books.path,
      icon: <MenuBookIcon />,
    },
    {
      title: "Series",
      path: routeConstant.series.path,
      icon: <MenuBookIcon />,
    },
    // {
    //   title: "Settings",
    //   path: routeConstant.settings.path,
    //   icon: <SettingsIcon />,
    // },
  ];
  let accountant = [
    {
      title: "Dashboard",
      path: routeConstant.dashboard.path,
      icon: <DashboardIcon />,
    },
    {
      title: "Accounts",
      path: routeConstant.accounts.path,
      icon: <PaymentIcon />,
    },
    {
      title: "Transactions",
      path: routeConstant.transactions.path,
      icon: <AccountBalanceIcon />,
    },
    // {
    //   title: "Settings",
    //   path: routeConstant.settings.path,
    //   icon: <SettingsIcon />,
    // },
  ];
  let manager = [
    {
      title: "Dashboard",
      path: routeConstant.dashboard.path,
      icon: <DashboardIcon />,
    },
    // {
    //   title: "Sales of book",
    //   icon: <AutoStoriesIcon />,
    //   open: false,
    //   subMenu: [
    //     {
    //       title: "Soft-copy sales",
    //       path: routeConstant.softCopy.path,
    //       subMenuOf: "Sales of book",
    //     },
    //     {
    //       title: "Hard-copy sales",
    //       path: routeConstant.hardCopy.path,
    //       subMenuOf: "Sales of book",
    //     },
    //   ],
    // },
    {
      title: "Aliflaila Users",
      icon: <Icons.UserGroup />,
      subMenu: [
        {
          title: "Individuals",
          path: routeConstant.aliflailaIndividualUsers.path,
          subMenuOf: "Aliflaila Users",
        },
        {
          title: "Family / Parent",
          path: routeConstant.parentUsers.path,
          subMenuOf: "Aliflaila Users",
        },
      ],
    },
    {
      title: "Sales of book",
      icon: <AutoStoriesIcon />,
      path: routeConstant.sales.path,
    },
    {
      title: "Books catalog",
      path: routeConstant.booksCatalog.path,
      icon: <LibraryBooksIcon />,
    },
    {
      //title: "Membership",
      title: "Plans",
      icon: <LoyaltyIcon />,
      subMenu: [
        {
          title: "Membership Plans",
          path: routeConstant.membership.path,
          subMenuOf: "Plans",
        },
        {
          title: "Manual Payment Requests",
          path: routeConstant.subscriptionRequest.path,
          // icon: <LoyaltyIcon></LoyaltyIcon>,
          subMenuOf: "Plans",
        },
        {
          title: "Subscription History",
          path: routeConstant.subscriptions.path,
          // icon: <LoyaltyIcon></LoyaltyIcon>,
          subMenuOf: "Plans",
        },
        {
          title: "Pending Payments",
          path: routeConstant.transactionHistory.path,
          // icon: <LoyaltyIcon></LoyaltyIcon>,
          subMenuOf: "Plans",
        },
      ],
    },
    {
      title: "Featured books",
      path: routeConstant.featuredBooks.path,
      icon: <BeenhereIcon />,
    },
    {
      title: "Customer support",
      path: routeConstant.customerSupport.path,
      icon: <SupportAgentIcon />,
    },
    {
      title: "Reported Books",
      path: routeConstant.requiredBooks.path,
      icon: <ImportContactsIcon />,
    },
    // {
    //   title: "Settings",
    //   path: routeConstant.settings.path,
    //   icon: <SettingsIcon />,
    // },
    {
      title: "Newsletters",
      path: routeConstant.newsletterSubscription.path,
      icon: <NewspaperIcon />,
    },
    {
      title: "Banners",
      path: routeConstant.banners.path,
      icon: <ViewCarouselIcon />,
    },
    {
      title: "Notifications",
      path: routeConstant.notificationListings.path,
      icon: <NotificationsIcon />,
    },
    {
      title: "Announcement",
      path: routeConstant.bannerControl.path,
      icon: <CampaignIcon />,
    },
  ];
  let head = [
    {
      title: "Dashboard",
      path: routeConstant.dashboard.path,
      icon: <DashboardIcon />,
    },
    {
      title: "MOU",
      path: routeConstant.allMou.path,
      icon: <ArticleIcon />,
    },
    {
      title: "Transactions",
      path: routeConstant.transactions.path,
      icon: <AccountBalanceIcon />,
    },
    {
      title: "Subscription History",
      path: routeConstant.subscriptions.path,
      icon: <LoyaltyIcon />,
    },
    {
      title: "Best Sellers",
      path: routeConstant.bestSellers.path,
      icon: <ImportContactsIcon />,
    },
    {
      title: "Reviewers Performace",
      path: routeConstant.reviewersPerformance.path,
      icon: <AutoGraphIcon />,
    },
    {
      title: "Partners Account",
      path: routeConstant.partnerAccount.path,
      icon: <HandshakeIcon />,
    },
    {
      title: "Reported Users",
      path: routeConstant.reportedUsers.path,
      icon: <ReportIcon />,
    },
    {
      title: "Newsletters",
      path: routeConstant.newsletterSubscription.path,
      icon: <NewspaperIcon />,
    },
    {
      title: "Notifications",
      path: routeConstant.notificationListings.path,
      icon: <NotificationsIcon />,
    },
    // {
    //   title: "Settings",
    //   path: routeConstant.settings.path,
    //   icon: <SettingsIcon />,
    // },
  ];

  let Routes: any = [
    {
      role_id: 1,
      title: "Project Head",
      routes: head,
    },
    {
      role_id: 2,
      title: "HOD",
      routes: hod,
    },
    {
      role_id: 3,
      title: "Book Reviewer",
      routes: bookReviewer,
    },
    {
      role_id: 4,
      title: "Product Manager",
      routes: manager,
    },
    {
      role_id: 5,
      title: "Accountant",
      routes: accountant,
    },
    {
      role_id: 6,
      title: "Admin",
      routes: admin,
    },
  ];

  return Routes;
}

export { getRoutes };
