import {
  Box,
  Card,
  CardContent,
  Paper,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BookInfoCard from "shared/components/bookInfoCard";
import { toastMessage } from "shared/components/toast";
import {getSeriesDetailsService} from "shared/services/general";
import styles from "./style.module.scss";
import { useSelector } from "react-redux";
import { getRecentHistoryBookReviewer } from "shared/services/bookReviewerService";
import {getBookHistoryService, getSeriesHistoryService} from "shared/services/hodService";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import PaginationComponent from "shared/components/pagination";
import SkeletonLoader from "./skeletonLoader";
// import { LastPage } from "@mui/icons-material";
import { Icons } from "assets";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import { routeConstant } from "shared/routes/routesConstant";
import { rolesRoutes } from "shared/routes/allRoutes";
import { getRevisionHistoryByProductManager } from "shared/services/productManagerService";
import { roles } from "shared/utils/constants";
import SeriesInfoCard from "../../shared/components/seriesInfoCard";

function SeriesRevisions() {
  const { id } = useParams<{ id: string }>() as { id: string };
  const { user } = useSelector((state: any) => state.root);
  const [seriesDetail, setSeriesDetail] = useState<any>(null);
  const [seriesDetailLoader, setSeriesDetailLoader] = useState<boolean>(false);
  const [history, setHistory] = useState<any>(null);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [activePage, setActivePage] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);



  const getSeriesHistory = () => {
    setLoader(true);

    let service =
      Number(user?.role?.role_id) === roles.bookReviewer
        ? getRecentHistoryBookReviewer
        : Number(user?.role?.role_id) === roles.productManager
        ? getRevisionHistoryByProductManager
        : getSeriesHistoryService;

    service(seriesDetail ? seriesDetail?.id : null, activePage)
      .then(({ data }) => {
        setHistory(data?.data?.data);
        setTotalPage(data?.data?.last_page);
        setLoader(false);
      })
      .catch((error) => {
        toastMessage("error", error?.response?.data?.message);
        console.log("error", error?.response?.data?.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    if (seriesDetail) {
      getSeriesHistory();
    }
    // eslint-disable-next-line
  }, [seriesDetail, activePage]);

  const getSeriesDetails = () => {
    setSeriesDetailLoader(true);
    getSeriesDetailsService(id)
      .then(({ data }) => {
        setSeriesDetail(data?.data);
        setSeriesDetailLoader(false);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
        setSeriesDetailLoader(false);
      });
  };

  useEffect(() => {
    getSeriesDetails();
    // eslint-disable-next-line
  }, [id]);

  useBreadcrumbs(
    {
      title: "Series Revision",
      path: routeConstant.seriesRevisions.path.replace(":id", id),
    },
    true
  );

  return (
    <div>
      <Card>
        <CardContent>
          <SeriesInfoCard series={seriesDetail} loader={seriesDetailLoader} />

          {history?.length === 0 ? (
            <div>
              <div className={styles.noUserCard}>
                <Icons.NoDataIcon />
                <h5>No Revisions Found</h5>
              </div>
            </div>
          ) : (
            history?.map((item: any, inx: any) => {
              return (
                <Box marginTop={"20px"}>
                  <div className={styles.headingContainer}>
                    <div className={styles.title}>
                      <span className={styles.dot}>&#x2022;</span>
                      Series Revision {totalPage - (activePage - 1)}
                    </div>
                    <div className={styles.time}>
                      <span style={{ marginRight: "5px" }}>
                        {moment(item?.created_at).format("DD-MM-YYYY | H:SS A")}
                      </span>
                    </div>
                  </div>

                  <div className={styles.revisionDescription}>
                    <div>
                      {item?.response && (
                        <div className={styles.replySection}>
                          <div style={{ fontSize: "16px", fontWeight: "500" }}>
                            Publisher response:
                          </div>
                          <div className={styles.repliedText}>
                            {item?.response}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <TableContainer component={Paper} className={styles.table}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow className={styles.tableHead}>
                          <TableCell>Parameter</TableCell>
                          <TableCell>Response</TableCell>
                          <TableCell>Comments</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {loader ? (
                          <SkeletonLoader />
                        ) : (
                          //role 3 is for book reviewer.
                          user?.role?.role_id !== 3 &&
                          item?.data?.intial_review &&
                          Object.keys(item?.data?.intial_review)
                            .map((key) => item?.data?.intial_review[key])
                            ?.map((itm: any) => {
                              return (
                                <TableRow>
                                  <TableCell>{itm?.label}</TableCell>
                                  <TableCell>
                                    {itm?.type === "rating" ? (
                                      <Rating
                                        name="simple-controlled"
                                        value={itm?.value}
                                        readOnly
                                      />
                                    ) : Number(itm?.option) === 0 ? (
                                      "No"
                                    ) : (
                                      "Yes"
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {itm?.type === "checklist" &&
                                      itm?.options?.map((itms: any) => {
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            {itms?.value && (
                                              <>
                                                <CheckIcon
                                                  style={{ fill: "#9A469B" }}
                                                />{" "}
                                                <span
                                                  style={{ marginLeft: "10px" }}
                                                >
                                                  {itms?.name === "Others"
                                                    ? itms?.name +
                                                      ": " +
                                                      itms?.comment
                                                    : itms?.name}
                                                </span>
                                              </>
                                            )}
                                          </div>
                                        );
                                      })}

                                    {itm?.type === "dropdown" &&
                                      itm?.selected && (
                                        <div>
                                          Selected: {itm?.selected?.name}{" "}
                                          {itm?.selected?.text}
                                        </div>
                                      )}

                                    {itm?.type === "rating" && (
                                      <div>{itm?.comment}</div>
                                    )}

                                    {itm?.type === "text" && (
                                      <div>{itm?.comment}</div>
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                        )}

                        {!item?.data?.detailed_review &&
                        user?.role?.role_id === 3 ? (
                          <div>
                            <div className={styles.noUserCard}>
                              <Icons.NoDataIcon />
                              <h5>No Revisions Found</h5>
                            </div>
                          </div>
                        ) : null}

                        {item?.data?.detailed_review &&
                          Object.keys(item?.data?.detailed_review)
                            .map((key) => item?.data?.detailed_review[key])
                            ?.map((itm: any) => {
                              return (
                                <TableRow>
                                  <TableCell>{itm?.label}</TableCell>
                                  <TableCell>
                                    {itm?.type === "rating" ? (
                                      <Rating
                                        name="simple-controlled"
                                        value={itm?.value}
                                        readOnly
                                      />
                                    ) : Number(itm?.option) === 0 ? (
                                      "No"
                                    ) : (
                                      "Yes"
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {itm?.type === "checklist" &&
                                      itm?.options?.map((itms: any) => {
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            {itms?.value ? (
                                              <>
                                                <CheckIcon
                                                  style={{ fill: "#9A469B" }}
                                                />{" "}
                                                <span
                                                  style={{ marginLeft: "10px" }}
                                                >
                                                  {itms?.name}
                                                </span>
                                              </>
                                            ) : null}
                                          </div>
                                        );
                                      })}

                                    {itm?.type === "dropdown" &&
                                      itm?.selected && (
                                        <div>
                                          Selected: {itm?.selected?.name}{" "}
                                          {itm?.selected?.text}
                                        </div>
                                      )}

                                    {itm?.type === "rating" && (
                                      <div>{itm?.comment}</div>
                                    )}
                                    {itm?.type === "text" && (
                                      <div>{itm?.comment}</div>
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {totalPage > 1 && (
                    <PaginationComponent
                      totalPages={totalPage}
                      activePage={activePage}
                      setActivePage={setActivePage}
                    />
                  )}
                </Box>
              );
            })
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default SeriesRevisions;
