import {
  CardContent,
  Card,
  Grid,
  // useMediaQuery,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import CustomButton from "shared/components/customButton";
import { Images } from "assets";
import { useNavigate, useParams } from "react-router-dom";
import {getSeriesDetailsService} from "shared/services/general";
import { toastMessage } from "shared/components/toast";
import { routeConstant } from "shared/routes/routesConstant";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";

function SeriesDetail() {
  const navigate = useNavigate();
  // const isMobile = useMediaQuery("(max-width:650px)");
  const { id }: any = useParams();

  const [seriesDetail, setSeriesDetail] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(false);

  const getSeriesDetails = () => {
    setLoader(true);
    getSeriesDetailsService(id)
      .then(({ data }) => {
        setSeriesDetail(data?.data);
        setLoader(false);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    getSeriesDetails();
    // eslint-disable-next-line
  }, [id]);

  useBreadcrumbs(
    {
      title: seriesDetail?.name,
      path: routeConstant.seriesDetail.path.replace(":id", seriesDetail?.id),
    },
    true,
    seriesDetail
  );

  return (
    <div>
      <Card className={styles.card}>
        <CardContent className={styles.cardContent}>
          <div className={styles.upperGrid}>
            <div className={styles.bookTitle}>{seriesDetail?.name}</div>
          </div>

          <div className={styles.mainContainer}>
            <Grid container spacing={2}>
              <Grid xs={12} md={4}>
                <div className={styles.imageContainer}>
                  {loader ? (
                    <Skeleton
                      variant="rectangular"
                      width={"280px"}
                      height={"360px"}
                    />
                  ) : (
                    <img
                      src={
                        seriesDetail?.cover_photo
                          ? seriesDetail?.cover_photo
                          : Images.BookSample2
                      }
                      alt="book"
                      className={styles.bookImage}
                    />
                  )}
                </div>
              </Grid>
              <Grid xs={12} md={8}>
              </Grid>

              <div style={{ marginTop: "20px" }}>
                <div className={styles.bookInfo}>About this series</div>
                <div
                  className={
                    seriesDetail?.language?.name === "English"
                      ? styles.description
                      : styles.description
                  }
                  style={{ whiteSpace: "pre-line" }}
                >
                  {loader ? (
                    <Skeleton variant="rectangular" width={250} height={25} />
                  ) : seriesDetail?.description ? (
                      seriesDetail?.description
                  ) : (
                    "Not Provided"
                  )}
                </div>
              </div>
            </Grid>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default SeriesDetail;
