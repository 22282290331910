import React, {useEffect, useState} from "react";
import {Avatar, Box, Tooltip} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import styles from "../../../pages/dashboardHOD/style.module.scss";
import {bookStatusEnum} from "../../utils/constants";
import {Icons} from "../../../assets";
import {getPublishedBy, searchHandlerHelper} from "../../utils/helpers";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import SkeletonLoader from "../../../pages/dashboardHOD/skeletonLoader";
import moment from "moment/moment";
import {routeConstant} from "../../routes/routesConstant";
import PreviewIcon from "@mui/icons-material/Preview";
import BugReportIcon from "@mui/icons-material/BugReport";
import Pagination from "../pagination";
import ItemsPerPage from "../itemsPerPage";
import Loader from "../loader/animation";
import Confirmation from "../../modals/confirmation";
import AssignReviewer from "../../modals/assignReviewer";
import Revision from "../../modals/revision";
import {useNavigate} from "react-router-dom";
import useWindowSize from "../../customHooks/windowSize";
import {
    changeBookStatusService,
    getAllBooksService, publishBookService,
    recentRevisionByHOD
} from "../../services/hodService";
import {toastMessage} from "../toast";
import useDebounce from "../../customHooks/useDebounce";
import RejectSeries from "../../modals/rejectSeries";

const DashboardAudio: React.FC = () => {
    const navigate = useNavigate();
    const [openReject, setOpenReject] = useState<boolean>(false);
    const [openRevision, setOpenRevision] = useState<boolean>(false);
    const [openConfirm, setOpenConfirm] = useState<boolean>(false);
    const [selectedBook, setSelectedBook] = useState<any>(null);
    const [activePage, setActivePage] = useState<number>(1);
    const [openReview, setOpenReview] = useState<boolean>(false);
    const [totalPage, setTotalPage] = useState<number>(10);
    const screenWidth = useWindowSize();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const [searchText, setSearchText] = useState<string>("");
    const [status, setStatus] = useState<any>(0);
    const [allBooks, setAllBooks] = useState<any>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [navigateLoader, setNavigateLoader] = useState<boolean>(false);
    const [rejectViewOnly, setRejectViewOnly] = useState<boolean>(false);
    const [totalItems, setTotalItems] = useState<number>(1);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);

    const handleChange = (event: any, newValue: number) => {
        setStatus(newValue);

    };

    const handleRejectBook = () => {
        setOpenReject(false);
        setSelectedBook(null);
        setRejectViewOnly(false);
    };

    const handleConfirmBook = (item: any) => {
        setOpenConfirm(true);
        setSelectedBook(item);
    };

    const handleConfirmClose = () => {
        setOpenConfirm(false);
        setSelectedBook(null);
    };

    const handleOpenRejectBook = (item: any) => {
        setOpenReject(true);
        setSelectedBook(item);
    };

    const handleReviewClose = () => {
        setOpenReview(false);
        setSelectedBook(null);
    };

    // const handleRevisionOpen = (item: any) => {
    //   setOpenRevision(true);
    //   setSelectedBook(item);
    // };

    const handleCloseRevision = () => {
        setOpenRevision(false);
        setSelectedBook(null);
    };
    const getBooks = () => {
        setLoader(true);
        getAllBooksService(search, -1, activePage, itemsPerPage, status)
            .then(({ data: { data } }) => {
                setLoader(false);
                setAllBooks(data?.data);
                setTotalPage(data?.last_page);
                setTotalItems(data?.total);
                setCurrentPage(data?.current_page);
                setItemsPerPage(data?.per_page);
            })
            .catch((err) => {
                toastMessage("error", err?.response?.data?.message);
                setLoader(false);
                console.log("err", err?.response?.data?.message);
            });
    };

    useEffect(() => {
        getBooks();
        // eslint-disable-next-line
    }, [searchText, status, activePage, itemsPerPage]);

    useDebounce(
        () => {
            setSearchText(search);
        },
        [search],
        800
    );

    const changeStatus = (item: any) => {
        changeBookStatusService({ book_id: item?.id, status: 1 })
            .then(({ data }) => {})
            .catch((err) => {
                toastMessage("error", err?.response?.data?.message);
                console.log("err", err?.response?.data?.message);
            });
    };

    const handleRevisionNavigation = (item: any) => {
        setNavigateLoader(true);
        recentRevisionByHOD(item?.id)
            .then(({ data }) => {
                if (data?.data?.data?.detailed_review) {
                    navigate(routeConstant.review.path.replace(":id", item?.id));
                    changeStatus(item);
                } else {
                    navigate(routeConstant.reviewByHOD.path.replace(":id", item?.id));
                }
                setNavigateLoader(false);
            })
            .catch((err) => {
                console.log("err", err?.response?.data?.message);
                toastMessage("error", err?.response?.data?.message);
                setNavigateLoader(false);
            });
    };

    const handleAction = () => {
        let resp: any = {
            book_id: selectedBook?.id,
            feedback: selectedBook?.feedback,
            rating: selectedBook?.rating,
        };
        setNavigateLoader(true);
        publishBookService(resp)
            .then(({ data }) => {
                if (data?.statusCode === 200) {
                    toastMessage("success", data?.message);
                    getBooks();
                } else {
                    toastMessage("error", data?.message);
                }
                setNavigateLoader(false);
                handleConfirmClose();
            })
            .catch((err) => {
                toastMessage("error", err?.response?.data?.message);
                console.log("err", err?.response?.data?.message);
                setNavigateLoader(false);
            });
    };


    return (
      <>

        <Box sx={{ width: "100%", marginTop: "40px" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
                value={status}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#9A469B",
                    color: "red !important",
                  },
                }}
                sx={{
                  "& .MuiTab-root.Mui-selected": {
                    color: "#9A469B",
                  },
                }}
            >
              <Tab label="Newly Submitted" className={styles.tabs} />
              <Tab label="In review" className={styles.tabs} />
              <Tab label="Revision Requested" className={styles.tabs} style={{display:'none'}} />
              <Tab label="Revision Submitted" className={styles.tabs} style={{display:'none'}}/>
              <Tab label="Approved" className={styles.tabs} />
              <Tab label="Published" className={styles.tabs} style={{display:'none'}}/>
              <Tab label="Rejected" className={styles.tabs} />
            </Tabs>
          </Box>
        </Box>
        <Box>
          <Box className={styles.topHeadContainer} style={{marginBottom: '0'}}>
            <Box>
              <div className={styles.tableHeading}>
                {status === bookStatusEnum.pending
                    ? "Newly Submitted"
                    : status === bookStatusEnum.admin_review
                        ? "In Review"
                        : status === bookStatusEnum.revision_requested
                            ? "Revision Requested"
                            : status === bookStatusEnum.revision_submitted
                                ? "Revision Submitted"
                                : status === bookStatusEnum.approved
                                    ? "Approved"
                                    : status === bookStatusEnum.published
                                        ? "Published"
                                        : status === bookStatusEnum.rejected
                                            ? "Rejected"
                                            : "Recently Added books"}
              </div>
            </Box>
            <Box className={styles.filterContainer}>
              <div className={styles.gridContainer}>
                <Icons.SearchIcon className={styles.searchIcon} />
                <input
                    placeholder="Search"
                    className={styles.input}
                    value={search}
                    onChange={(e) =>
                        searchHandlerHelper(e, setSearch, setActivePage)
                    }
                />
              </div>
            </Box>
          </Box>
            <TableContainer
                component={Paper}
                className={styles.table}
                sx={screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}}
            >
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow className={styles.tableHead}>
                            <TableCell>Book Name</TableCell>
                            <TableCell>Published by</TableCell>
                            <TableCell>Language</TableCell>
                            <TableCell>Age</TableCell>
                            <TableCell>Genre</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loader ? (
                            <SkeletonLoader
                                lines={
                                    status === bookStatusEnum.pending ||
                                    status === bookStatusEnum.approved ||
                                    status === bookStatusEnum.rejected
                                        ? 2
                                        : status === bookStatusEnum.published
                                            ? 6
                                            : status === bookStatusEnum.admin_review ||
                                            status === bookStatusEnum.revision_requested ||
                                            status === bookStatusEnum.revision_submitted
                                                ? 5
                                                : 1
                                }
                                actions={
                                    status === bookStatusEnum.pending ||
                                    status === bookStatusEnum.admin_review ||
                                    status === bookStatusEnum.revision_requested ||
                                    status === bookStatusEnum.revision_submitted ||
                                    status === bookStatusEnum.rejected
                                        ? 3
                                        : status === bookStatusEnum.approved
                                            ? 4
                                            : status === bookStatusEnum.published
                                                ? 2
                                                : 1
                                }
                            />
                        ) : (
                            allBooks?.map((item: any) => {
                                const genreNames = item?.genres.map((genre: any) => {
                                    return `${genre.name}, `;
                                });
                                const genreString = genreNames.join(" ").slice(0, -1);
                                const genreStringLength = genreString.length;
                                const genre = genreString.substring(0, genreStringLength - 1);

                                return (
                                    <>
                                        <TableRow className={styles.tableRow}>
                                            <TableCell>
                                                <Box display={"flex"} alignItems={"center"}>
                                                    <img
                                                        src={item?.thumbnail}
                                                        alt="book cover"
                                                        className={styles.bookCover}
                                                    />
                                                    <span style={{ marginLeft: "10px" }}>
                              {item?.title}
                                                        <div className={styles.name}>
                                @{item?.book_author?.name}
                              </div>
                            </span>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box display={"flex"} alignItems={"center"}>
                                                    <img
                                                        src={getPublishedBy(item?.partner)?.img}
                                                        alt="book cover"
                                                        style={{ borderRadius: "50%" }}
                                                        className={styles.bookCover}
                                                    />
                                                    <span style={{ marginLeft: "10px" }}>
                              {getPublishedBy(item?.partner)?.name}
                            </span>
                                                </Box>
                                            </TableCell>
                                            {Number(item?.status) === bookStatusEnum.rejected && (
                                                <TableCell>
                                                    {item?.book_review_assignment
                                                        ? item?.book_review_assignment?.reviewer
                                                            ?.full_name
                                                        : "--"}
                                                </TableCell>
                                            )}
                                            {Number(item?.status) === bookStatusEnum.published && (
                                                <>
                                                    <TableCell>{item?.language?.name}</TableCell>
                                                    <TableCell>
                                                        {item?.age_range?.min} - {item?.age_range?.max}
                                                    </TableCell>
                                                    <TableCell id={styles.expandedTableCell}>
                                                        {genre}
                                                    </TableCell>
                                                </>
                                            )}


                                            <TableCell>
                                                <Box
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                    className={styles.actionBtn}
                                                >
                                                    {Number(item?.status) !== bookStatusEnum.pending &&
                                                        Number(item?.status) !==
                                                        bookStatusEnum.admin_review && (
                                                            <Tooltip arrow title="Book Revisions">
                                                                <a href={routeConstant.bookAudioRevisions.path.replace(":id", item?.id)}>
                                                                    <PreviewIcon />
                                                                </a>
                                                            </Tooltip>
                                                        )}

                                                    {Number(item?.status) ===
                                                        bookStatusEnum.rejected && (
                                                            <Tooltip arrow title="Reason of Rejection">
                                                                <button
                                                                    onClick={() => {
                                                                        handleOpenRejectBook(item);
                                                                        setRejectViewOnly(true);
                                                                    }}
                                                                >
                                                                    <BugReportIcon />
                                                                </button>
                                                            </Tooltip>
                                                        )}

                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {!(allBooks.length > 0) && !loader && (
                <div className={styles.noUserCard}>
                    <Icons.NoDataIcon />
                    <h5>No Data Found</h5>
                </div>
            )}
            {totalPage > 1 && (
                <Pagination
                    totalPages={totalPage}
                    activePage={activePage}
                    setActivePage={setActivePage}
                />
            )}
            {allBooks.length > 0 ? (
                <ItemsPerPage
                    setActivePage={setActivePage}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    totalItems={totalItems}
                ></ItemsPerPage>
            ) : null}
            {navigateLoader && <Loader />}
        </Box>
        <RejectSeries
            open={openReject}
            handleClose={handleRejectBook}
            selectedBook={selectedBook}
            isRating
            rejectViewOnly={rejectViewOnly}
        />
        <Confirmation
            text="Are you sure, you want to publish this book ?"
            open={openConfirm}
            handleClose={handleConfirmClose}
            handleClick={handleAction}
        />
        <AssignReviewer
            open={openReview}
            handleClose={handleReviewClose}
            selectedBook={selectedBook}
        />
        <Revision
            open={openRevision}
            handleClose={handleCloseRevision}
            statsBool={false}
            selectedBook={selectedBook}
        />
      </>
  );
};

export default DashboardAudio;
