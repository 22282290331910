import { Box, Grid, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import Card from "shared/components/card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styles from "./style.module.scss";
import { Icons } from "assets";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Pagination from "shared/components/pagination";
import RateReviewIcon from "@mui/icons-material/RateReview";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
// import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import { routeConstant } from "shared/routes/routesConstant";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import useWindowSize from "shared/customHooks/windowSize";
import moment from "moment";
// import RejectBook from "shared/modals/rejectBook";
import AssignReviewer from "shared/modals/assignReviewer";
import Confirmation from "shared/modals/confirmation";
import { useSelector } from "react-redux";
import {
  getBookReviewerStats,
  getBookReviewersBooksService,
} from "shared/services/bookReviewerService";
import { toastMessage } from "shared/components/toast";
import useDebounce from "shared/customHooks/useDebounce";
import SkeletonLoader from "./SkeletonLoader";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import ItemsPerPage from "shared/components/itemsPerPage";
import { searchHandlerHelper } from "shared/utils/helpers";
import DashboardSeries from "../../shared/components/dashboardSeries";
import DashboardSeriesReviewers from "../../shared/components/dashboardSeriesReviewers";

function DashboardBookReviewer() {
  const navigate = useNavigate();
  // const [openReject, setOpenReject] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openReview, setOpenReview] = useState<boolean>(false);
  const [selectedBook, setSelectedBook] = useState<any>(null);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [dashboardData, setDashboardData] = useState<any>([]);
  const screenWidth = useWindowSize();
  // const [totalPage, setTotalPage] = useState<number>(10);
  const { user } = useSelector((state: any) => state.root);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [allBooks, setAllBooks] = useState<any>([]);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);

  const cardData = [
    {
      title: "Total books assigned",
      icon: LibraryAddCheckIcon,
      stats: dashboardData?.total,
    },
    {
      title: "Total books reviewed",
      icon: DoneOutlineIcon,
      stats: dashboardData?.reviewed,
    },
    {
      title: "Total books in review process",
      icon: PendingActionsIcon,
      stats: dashboardData?.in_review,
    },
  ];

  const handleClick = () => {
    setOpenDelete(false);
  };

  // const handleRejectBook = () => {
  //   setOpenReject(false);
  //   setSelectedBook(null);
  // };

  // const handleConfirmBook = (item: any) => {
  //   setOpenConfirm(true);
  //   setSelectedBook(item);
  // };

  const handleConfirmClose = () => {
    setOpenConfirm(false);
    setSelectedBook(null);
  };

  // const handleOpenRejectBook = (item: any) => {
  //   setOpenReject(true);
  //   setSelectedBook(item);
  // };

  const handleReviewOpen = (item: any) => {
    setOpenReview(true);
    setSelectedBook(item);
  };

  const handleReviewClose = () => {
    setOpenReview(false);
    setSelectedBook(null);
  };

  const getAllBooks = () => {
    setLoader(true);
    getBookReviewersBooksService(searchText, 7, activePage, itemsPerPage)
      .then(({ data }) => {
        setLoader(false);
        setTotalPage(data?.data?.last_page);
        setAllBooks(data?.data?.data);
        setTotalItems(data?.data?.total);
        setItemsPerPage(data?.data?.per_page);
        setCurrentPage(data?.data?.current_page);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
        setLoader(false);
      });
  };

  const getStats = () => {
    setIsLoading(true);
    getBookReviewerStats()
      .then(({ data }) => {
        setIsLoading(false);
        setDashboardData(data?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    getStats();
  }, []);

  useDebounce(
    () => {
      setSearchText(search);
    },
    [search],
    800
  );

  useEffect(() => {
    getAllBooks();
    // eslint-disable-next-line
  }, [searchText, activePage, itemsPerPage, activePage]);

  useBreadcrumbs({
    title: "Dashboard",
    path: routeConstant.dashboard.path,
  });

  return (
    <div>
      <Grid container spacing={2}>
        {cardData?.map((item) => {
          return (
            <Grid item xs={12} sm={4}>
              <Card
                Icon={item?.icon}
                stats={item?.stats}
                title={item?.title}
                isLoading={isLoading}
              />
            </Grid>
          );
        })}
      </Grid>
      <Box className={styles.topLevelContainer}>
        <Box className={styles.topHeadContainer}>
          <Box>
            <div className={styles.tableHeading}>Newly Assigned Books</div>
            <div className={styles.tableSubHeading}>
              Here is the listing of all books assgined by HOD to review.
            </div>
          </Box>
          <Box className={styles.filterContainer}>
            <div className={styles.gridContainer}>
              <Icons.SearchIcon className={styles.searchIcon} />
              <input
                placeholder="Search"
                className={styles.input}
                value={search}
                onChange={(e) =>
                  searchHandlerHelper(e, setSearch, setActivePage)
                }
              />
            </div>
          </Box>
        </Box>
        <TableContainer
          component={Paper}
          className={styles.table}
          sx={screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={styles.tableHead}>
                <TableCell>Book name</TableCell>
                <TableCell>Author name</TableCell>
                <TableCell>Genre</TableCell>
                <TableCell>Age range</TableCell>
                <TableCell>Language</TableCell>
                {/*

                <TableCell>Publisher name</TableCell> */}
                <TableCell>Assgined date</TableCell>
                <TableCell>Due date</TableCell>
                {/* <TableCell>Payment</TableCell> */}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <SkeletonLoader />
              ) : (
                allBooks?.map((item: any) => {
                  const genreNames = item?.book?.genres.map((genre: any) => {
                    return `${genre.name}, `;
                  });
                  const genreString = genreNames.join(" ").slice(0, -1);
                  const genreStringLength = genreString.length;
                  const genre = genreString.substring(0, genreStringLength - 1);
                  return (
                    <TableRow className={styles.tableRow}>
                      <TableCell id={styles.minWidth}>
                        <Box display={"flex"} alignItems={"center"}>
                          <img
                            src={item?.book?.thumbnail}
                            alt="book cover"
                            className={styles.bookCover}
                          />
                          <span style={{ marginLeft: "10px" }}>
                            {item?.book?.title}
                          </span>

                          {!item?.book?.latest_revision?.data
                            ?.detailed_review && (
                            <Icons.NewIcon style={{ marginLeft: "10px" }} />
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>{item?.book?.book_author?.name}</TableCell>
                      <TableCell>
                        {item?.book?.genres ? genre : "Not Provided"}
                      </TableCell>
                      <TableCell>
                        {item?.book?.age_range
                          ? item?.book?.age_range.text.split(" ")[1]
                          : "Not Provided"}
                      </TableCell>
                      <TableCell>
                        {item?.book?.language
                          ? item?.book?.language?.name
                          : "Not Provided"}
                      </TableCell>
                      {/*

                      <TableCell>{item?.publisherName}</TableCell> */}
                      <TableCell>
                        {moment(item?.schedule?.assign_date).format(
                          "D-MM-YYYY"
                        )}
                      </TableCell>
                      <TableCell>
                        {moment(item?.schedule?.review_date).format(
                          "D-MM-YYYY"
                        )}
                      </TableCell>
                      {/* <TableCell>{"Rs." + item?.payment}</TableCell> */}
                      <TableCell>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          className={styles.actionBtn}
                        >
                          <Tooltip title="Book Details" arrow>
                            <button
                              onClick={() =>
                                navigate(
                                  routeConstant.bookDetail.path.replace(
                                    ":id",
                                    item?.book?.id
                                  )
                                )
                              }
                            >
                              <RemoveRedEyeIcon />
                            </button>
                          </Tooltip>

                          {/* <Tooltip title="Approve" arrow>
                            <button onClick={() => handleConfirmBook(item)}>
                              <CheckCircleIcon />
                            </button>
                          </Tooltip> */}

                          {/* <Tooltip title="Reject" arrow>
                            <button onClick={() => handleOpenRejectBook(item)}>
                              <CancelIcon />
                            </button>
                          </Tooltip> */}

                          <Tooltip title="Review" arrow>
                            <button
                              onClick={() =>
                                navigate(
                                  routeConstant.review.path.replace(
                                    ":id",
                                    item?.book_id
                                  )
                                )
                              }
                            >
                              <RateReviewIcon />
                            </button>
                          </Tooltip>

                          {user?.user?.email === "hod@yopmail.com" && (
                            <button onClick={() => handleReviewOpen(item)}>
                              <AssignmentIndIcon />
                            </button>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!(allBooks?.length > 0) && !loader && (
          <div className={styles.noUserCard}>
            <Icons.NoDataIcon />
            <h5>No Data Found</h5>
          </div>
        )}
        {totalPage > 1 && (
          <Pagination
            totalPages={totalPage}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}
        {allBooks?.length > 0 ? (
          <ItemsPerPage
            setActivePage={setActivePage}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            totalItems={totalItems}
          ></ItemsPerPage>
        ) : null}
      </Box>
      <Confirmation
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        handleClick={handleClick}
        text="Are you sure, you want to delete this publisher?"
      />
      {/* <RejectBook
        open={openReject}
        handleClose={handleRejectBook}
        selectedBook={selectedBook}
      /> */}
      <Confirmation
        text="Are you sure, you want to approve this book ?"
        open={openConfirm}
        handleClose={handleConfirmClose}
      />
      <AssignReviewer open={openReview} handleClose={handleReviewClose} />
    </div>
  );
}

export default DashboardBookReviewer;
