const user_roles = {
  school: 1,
  teacher: 2,
  parent: 3,
  reader: 4, // kid, student
  author: 5,
  publisher: 6,
};

const roles = {
  projectHead: 1,
  hod: 2,
  bookReviewer: 3,
  productManager: 4,
  accountant: 5,
  admin: 6,
};

const bookStatusEnum = {
  pending: 0,
  admin_review: 1,
  revision_requested: 2,
  revision_submitted: 3,
  approved: 4,
  published: 5,
  rejected: 6,
  internal_review: 7,
  review_completed: 8,
};

const languageEnum = {
  english: "English",
  urdu: "Urdu",
};

const options = [
  { label: "Yes", value: 1 },
  { label: "No", value: 0 },
];

const checklistOption = [
  { name: "It is not the same as in Cover Page", id: 1, value: false },
  { name: "Spelling & punctuation", id: 2, value: false },
  { name: "Others", id: 3, value: false, comment: "" },
];

const initialForm = {
  rating: { value: 0 },
  intial_review: {
    title: {
      option: options[0].value,
      comment: "Following list topics are for review.",
      type: "checklist",
      checked: false,
      options: checklistOption.map((item) => ({ ...item, value: false })),
      label: "Title",
    },
    author: {
      option: options[0].value,
      comment: "Following list topics are for review.",
      type: "checklist",
      checked: false,
      options: checklistOption.map((item) => ({ ...item, value: false })),
      label: "Author",
    },
    borrow_coins: {
      option: options[0].value,
      comment: "",
      type: "text",
      checked: false,
      placeholder: "Enter manually",
      label: "Borrow Coins",
    },
    buy_coins: {
      option: options[0].value,
      comment: "",
      type: "text",
      checked: false,
      placeholder: "Enter manually",
      label: "Buy Coins",
    },
    language: {
      option: options[0].value,
      comment: "",
      type: "dropdown",
      checked: false,
      selected: null,
      options: null,
      placeholder: "Select other language",
      label: "Language",
    },
  },
  intial_series_review: {
    name: {
      option: options[0].value,
      comment: "Following list topics are for review.",
      type: "checklist",
      checked: false,
      options: checklistOption.map((item) => ({ ...item, value: false })),
      label: "Name",
    },
    description: {
      option: options[0].value,
      comment: "Following list topics are for review.",
      type: "checklist",
      checked: false,
      options: checklistOption.map((item) => ({ ...item, value: false })),
      label: "Description",
    }
  },
  detailed_series_review: {
  },
  detailed_review: {
    genre: {
      option: options[0].value,
      comment: "Suggested list topics.",
      type: "checklist",
      checked: false,
      options: null,
      label: "Genre",
    },
    // keywords: {
    //   option: options[0].value,
    //   comment: "",
    //   type: "dropdown",
    //   checked: false,
    //   selected: null,
    //   options: null,
    //   placeholder: "Selection from the list",
    //   label: "Keywords",
    // },
    keywords: {
      option: options[0].value,
      comment: "Suggested keywords.",
      type: "checklist",
      checked: false,
      options: null,
      label: "Keywords",
    },
    book_description: {
      comment: "",
      type: "rating",
      value: 0,
      checked: false,
      placeholder: "Comment",
      label: "Book Description",
    },
    age_appropriateness: {
      option: options[0].value,
      comment: "",
      type: "dropdown",
      checked: false,
      selected: null,
      options: null,
      placeholder: "Suggest the age range",
      label: "Age Appropriateness",
    },
    language: {
      comment: "",
      type: "rating",
      value: 0,
      checked: false,
      placeholder: "Comments",
      label: "Language",
    },
    content_engagement: {
      comment: "",
      type: "rating",
      value: 0,
      checked: false,
      placeholder:
        "Provide some suggestions to enhance the content's engagement for readers.",
      label: "Content Engagement",
    },
    compliance: {
      option: options[0].value,
      comment: "",
      type: "text",
      checked: false,
      placeholder:
        "Suggest censoring lines that do not comply with culture and values.",
      label: "Compliance with Culture and Values",
    },
    cover_page: {
      option: options[0].value,
      comment: "",
      type: "text",
      checked: false,
      placeholder: "",
      label: "Cover Page",
    },
    illustration: {
      comment: "",
      type: "rating",
      value: 0,
      checked: false,
      placeholder: "",
      label: "Illustration",
    },
    readability: {
      option: options[0].value,
      comment: "",
      type: "text",
      checked: false,
      placeholder: "",
      label: "Readability",
    },
    layout: {
      comment: "",
      type: "rating",
      value: 0,
      checked: false,
      placeholder: "",
      label: "Layout",
    },
  },
  reject_reason: {},
};

enum ticketStatus {
  Open = 1,
  Closed = 2,
}
export {
  options,
  checklistOption,
  initialForm,
  bookStatusEnum,
  languageEnum,
  roles,
  user_roles,
  ticketStatus,
};
