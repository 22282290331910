import React, {useEffect, useRef, useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Icons } from "assets";
import styles from "./style.module.scss";
import CustomButton from "shared/components/customButton";
import { toastMessage } from "shared/components/toast";
import Loader from "shared/components/loader/animation";

import {
  createGenreService,
  updateGenresService,
} from "shared/services/productManagerService";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface AddCategoryProps {
  open: boolean;
  handleClose: any;
  selectedCategory: any;
  getAllGenres: any;
}

export default function AddCategory({
  open,
  handleClose,
  selectedCategory,
  getAllGenres,
}: AddCategoryProps) {
  const [category, setCategory] = useState<any>("");
  const [loader, setLoader] = useState<any>(false);
  const [icon, setIcon] = useState({
    raw: null,
    preview: selectedCategory?.icon || "",
  });
  const iconRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (selectedCategory) {
      setCategory(selectedCategory?.name);
    }
    // eslint-disable-next-line
  }, [selectedCategory]);

  const handleSubmit = () => {
    let service = selectedCategory ? updateGenresService : createGenreService;

    // let resp: any = {
    //   name: category,
    // };
    //
    // if (selectedCategory) {
    //   resp["id"] = selectedCategory?.id;
    // }
    const formData = new FormData();
    formData.append("name", category);

    if (icon.raw) {
      formData.append("icon", icon.raw);
    }

    if (selectedCategory) {
      formData.append("id", selectedCategory?.id);
    }

    setLoader(true);
    if (category.length) {
      service(formData)
        .then(({ data }) => {
          setLoader(false);
          toastMessage("success", data?.message);
          onClose();
          getAllGenres();
        })
        .catch((err) => {
          console.log("Err", err?.response?.data?.message);
          toastMessage("error", err?.response?.data?.message);
          setLoader(false);
        });
    } else {
      toastMessage("error", "Please type in something to proceed.");
      setLoader(false);
    }
  };

  const onClose = () => {
    handleClose();
    setCategory("");
    setIcon({ raw: null, preview: "" });
  };


  const handleImageChange = (e: any, banner: boolean) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
          setIcon({
            preview: e.target.result as string,
            raw: file,
          });
      };

      reader.readAsDataURL(file);
    }
  };

  const handleImageDelete = () => {
      setIcon({ preview: "", raw: null });
      if (iconRef.current) {
        iconRef.current.value = "";
      }
  };

  const handleButtonClick = () => {
      if (iconRef.current) {
        iconRef.current.click();
      }
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogContent>
          <Icons.CrossIcon
              className={styles.crossIcon}
              onClick={() => handleClose()}
          />

          <div className={styles.categoryTitle}>
            {selectedCategory ? "Update" : "Add"} Category
          </div>
          <input
              className={styles.inputCategory}
              value={category}
              onChange={(e) => setCategory(e.target.value)}
          />

          <div
              style={{marginTop: "15px"}}>
            <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageChange(e, true)}
                ref={iconRef}
                style={{display: "none"}}
            />
            <div className={styles.imgContainer}>
              {icon?.preview && (
                  <Icons.DeleteIcon
                      className={styles.deleteIcon}
                      onClick={() => handleImageDelete()}
                  />
              )}
              {icon.preview ? (
                  <img
                      src={icon.preview && icon.preview}
                      alt={"user"}
                      className={styles.uploadedPicture}
                      onClick={() => handleButtonClick()}
                  />
              ) : (
                  <div
                      className={styles.uploadContainer}
                      onClick={() => handleButtonClick()}
                  >
                    <Icons.UploadFileIcon/>
                    <label>Please Upload Icon</label>
                  </div>
              )}
            </div>
          </div>

          <div className={styles.btnContainer}>
            <CustomButton
                text={selectedCategory ? "Update" : "Add"}
                buttonStyle={styles.btnCustom}
                handleClick={() => handleSubmit()}
            />
            <CustomButton
                text="Cancel"
                buttonStyle={styles.btnInsideOut}
                handleClick={() => handleClose()}
            />
          </div>
        </DialogContent>

        {loader && <Loader/>}
      </Dialog>
    </div>
  );
}
