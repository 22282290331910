import React, {useEffect, useState} from "react";
import {Avatar, Box, Tooltip} from "@mui/material";
import styles from "../../../pages/dashboardHOD/style.module.scss";
import {Icons} from "../../../assets";
import {searchHandlerHelper} from "../../utils/helpers";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import SkeletonLoader from "../../../pages/dashboardHOD/skeletonLoader";
import moment from "moment/moment";
import {routeConstant} from "../../routes/routesConstant";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import RateReviewIcon from "@mui/icons-material/RateReview";
import Pagination from "../pagination";
import ItemsPerPage from "../itemsPerPage";
import Confirmation from "../../modals/confirmation";
import AssignReviewer from "../../modals/assignReviewer";
import {useNavigate} from "react-router-dom";
import useWindowSize from "../../customHooks/windowSize";
import {toastMessage} from "../toast";
import useDebounce from "../../customHooks/useDebounce";
import {getBookReviewersBooksService, getSeriesReviewersBooksService} from "../../services/bookReviewerService";

const DashboardSeriesReviewers: React.FC = () => {
  const navigate = useNavigate();
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [selectedBook, setSelectedBook] = useState<any>(null);
  const [activePage, setActivePage] = useState<number>(1);
  const [openReview, setOpenReview] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(10);
  const screenWidth = useWindowSize();
  const [search, setSearch] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [allBooks, setAllBooks] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
    const [openDelete, setOpenDelete] = useState<boolean>(false);



  const handleConfirmClose = () => {
    setOpenConfirm(false);
    setSelectedBook(null);
  };


  const handleReviewClose = () => {
    setOpenReview(false);
    setSelectedBook(null);
  };


    const getAllSeries = () => {
        setLoader(true);
        getSeriesReviewersBooksService(searchText, 7, activePage, itemsPerPage)
            .then(({ data }) => {
                setLoader(false);
                setTotalPage(data?.data?.last_page);
                setAllBooks(data?.data?.data);
                setTotalItems(data?.data?.total);
                setItemsPerPage(data?.data?.per_page);
                setCurrentPage(data?.data?.current_page);
            })
            .catch((err) => {
                toastMessage("error", err?.response?.data?.message);
                console.log("err", err?.response?.data?.message);
                setLoader(false);
            });
    };

    useEffect(() => {
        getAllSeries();
        // eslint-disable-next-line
    }, [searchText, activePage, itemsPerPage, activePage]);

  useDebounce(
      () => {
        setSearchText(search);
      },
      [search],
      800
  );

    const handleClick = () => {
        setOpenDelete(false);
    };

  return (
      <>


          <Box className={styles.topLevelContainer}>
              <Box className={styles.topHeadContainer}>
                  <Box>
                      <div className={styles.tableHeading}>Newly Assigned Series</div>
                      <div className={styles.tableSubHeading}>
                          Here is the listing of all series assgined by HOD to review.
                      </div>
                  </Box>
                  <Box className={styles.filterContainer}>
                      <div className={styles.gridContainer}>
                          <Icons.SearchIcon className={styles.searchIcon} />
                          <input
                              placeholder="Search"
                              className={styles.input}
                              value={search}
                              onChange={(e) =>
                                  searchHandlerHelper(e, setSearch, setActivePage)
                              }
                          />
                      </div>
                  </Box>
              </Box>
              <TableContainer
                  component={Paper}
                  className={styles.table}
                  sx={screenWidth > 990 ? { width: `${screenWidth - 300}px` } : {}}
              >
                  <Table aria-label="simple table">
                      <TableHead>
                          <TableRow className={styles.tableHead}>
                              <TableCell>Book name</TableCell>
                              {/*

                <TableCell>Publisher name</TableCell> */}
                              <TableCell>Assgined date</TableCell>
                              <TableCell>Due date</TableCell>
                              {/* <TableCell>Payment</TableCell> */}
                              <TableCell>Actions</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {loader ? (
                              <SkeletonLoader />
                          ) : (
                              allBooks?.map((item: any) => {
                                  console.log("item", item)
                                  return (
                                      <TableRow className={styles.tableRow}>
                                          <TableCell id={styles.minWidth}>
                                              <Box display={"flex"} alignItems={"center"}>
                                                  <img
                                                      src={item?.series?.cover_photo}
                                                      alt="book cover"
                                                      className={styles.bookCover}
                                                  />
                                                  <span style={{ marginLeft: "10px" }}>
                            {item?.book?.title}
                          </span>

                                                  {!item?.series?.latest_revision?.data
                                                      ?.detailed_review && (
                                                      <Icons.NewIcon style={{ marginLeft: "10px" }} />
                                                  )}
                                              </Box>
                                          </TableCell>
                                          {/*

                      <TableCell>{item?.publisherName}</TableCell> */}
                                          <TableCell>
                                              {moment(item?.schedule?.assign_date).format(
                                                  "D-MM-YYYY"
                                              )}
                                          </TableCell>
                                          <TableCell>
                                              {moment(item?.schedule?.review_date).format(
                                                  "D-MM-YYYY"
                                              )}
                                          </TableCell>
                                          {/* <TableCell>{"Rs." + item?.payment}</TableCell> */}
                                          <TableCell>
                                              <Box
                                                  display={"flex"}
                                                  alignItems={"center"}
                                                  className={styles.actionBtn}
                                              >
                                                  <Tooltip title="Book Details" arrow>
                                                      <button
                                                          onClick={() =>
                                                              navigate(
                                                                  routeConstant.seriesDetail.path.replace(
                                                                      ":id",
                                                                      item?.series?.id
                                                                  )
                                                              )
                                                          }
                                                      >
                                                          <RemoveRedEyeIcon />
                                                      </button>
                                                  </Tooltip>

                                                  {/* <Tooltip title="Approve" arrow>
                            <button onClick={() => handleConfirmBook(item)}>
                              <CheckCircleIcon />
                            </button>
                          </Tooltip> */}

                                                  {/* <Tooltip title="Reject" arrow>
                            <button onClick={() => handleOpenRejectBook(item)}>
                              <CancelIcon />
                            </button>
                          </Tooltip> */}

                                                  <Tooltip title="Review" arrow>
                                                      <button
                                                          onClick={() =>
                                                              navigate(
                                                                  routeConstant.reviewSeries.path.replace(
                                                                      ":id",
                                                                      item?.series_id
                                                                  )
                                                              )
                                                          }
                                                      >
                                                          <RateReviewIcon />
                                                      </button>
                                                  </Tooltip>

                                                  {/*{user?.user?.email === "hod@yopmail.com" && (*/}
                                                  {/*    <button onClick={() => handleReviewOpen(item)}>*/}
                                                  {/*        <AssignmentIndIcon />*/}
                                                  {/*    </button>*/}
                                                  {/*)}*/}
                                              </Box>
                                          </TableCell>
                                      </TableRow>
                                  );
                              })
                          )}
                      </TableBody>
                  </Table>
              </TableContainer>
              {!(allBooks?.length > 0) && !loader && (
                  <div className={styles.noUserCard}>
                      <Icons.NoDataIcon />
                      <h5>No Data Found</h5>
                  </div>
              )}
              {totalPage > 1 && (
                  <Pagination
                      totalPages={totalPage}
                      activePage={activePage}
                      setActivePage={setActivePage}
                  />
              )}
              {allBooks?.length > 0 ? (
                  <ItemsPerPage
                      setActivePage={setActivePage}
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                      setItemsPerPage={setItemsPerPage}
                      totalItems={totalItems}
                  ></ItemsPerPage>
              ) : null}
          </Box>
          <Confirmation
              open={openDelete}
              handleClose={() => setOpenDelete(false)}
              handleClick={handleClick}
              text="Are you sure, you want to delete this publisher?"
          />
          {/* <RejectBook
        open={openReject}
        handleClose={handleRejectBook}
        selectedBook={selectedBook}
      /> */}
          <Confirmation
              text="Are you sure, you want to approve this book ?"
              open={openConfirm}
              handleClose={handleConfirmClose}
          />
          <AssignReviewer open={openReview} handleClose={handleReviewClose} />
      </>
  );
};

export default DashboardSeriesReviewers;
