import { Avatar, Box, Rating, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { routeConstant } from "shared/routes/routesConstant";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import DashboardSeriesReviewers from "../../shared/components/dashboardSeriesReviewers";

function Series() {
  useBreadcrumbs({
    title: "Series",
    path: routeConstant.series.path,
  });

  return (
      <DashboardSeriesReviewers />
  );
}

export default Series;
