import { Endpoint } from "shared/utils/endpoints";
import { HTTP_CLIENT } from "../utils/interceptor";

const getDashboardData = () => {
  return HTTP_CLIENT.get(Endpoint.dashboard.adminDashboard);
};
const getUsersServices = (
  search: string,
  status: number,
  role: any,
  activePage: number,
  itemsPerPage: number
) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (status || status === 0) params.append("status", status.toString());
  if (role) params.append("role", role.toString());
  if (activePage) params.append("page", activePage.toString());
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());

  const url = `${Endpoint.admin.getUsers}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getBookReviewerStats = () => {
  return HTTP_CLIENT.get(Endpoint.admin.bookReviewers);
};

const getPublisherDataService = (id: any) => {
  return HTTP_CLIENT.get(Endpoint.admin.getPublisherProfile + id);
};

const getPublisherBooksService = (
  id: any,
  search: any,
  activePage: any,
  itemsPerPage: number
) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (activePage) params.append("page", activePage.toString());
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());
  const url = `${
    Endpoint.admin.getPublisherProfile + id + "/books"
  }?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getAllBookReviewersService = (
  search: string,
  activePage?: number,
  itemsPerPage?: number
) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (activePage) params.append("page", activePage.toString());
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());
  const url = `${Endpoint.admin.getAllBookReviewers}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const bookReviewerDetails = (id: any) => {
  return HTTP_CLIENT.get(Endpoint.admin.getBookReviewerDetails + id);
};

const getUsersService = () => {
  return HTTP_CLIENT.get(Endpoint.admin.getUsers);
};

const createTicketService = (resp: {}) => {
  return HTTP_CLIENT.post(Endpoint.admin.createTicket, resp);
};

const createTicketAndCommnetServiceByAdmin = (resp: {}) => {
  return HTTP_CLIENT.post(Endpoint.admin.createTicketAndComment, resp);
};

const addCommentService = (resp: any) => {
  let formBody = new FormData();
  Object.keys(resp).forEach((key) => {
    formBody.append(key, resp[key]);
  });
  return HTTP_CLIENT.post(Endpoint.admin.addComment, formBody);
};

const allTicketsService = (
  search: string,
  status: number,
  activePage: number,
  itemsPerPage: number
) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (status || status === 0) params.append("status", status.toString());
  if (activePage) params.append("page", activePage.toString());
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());

  const url = `${Endpoint.admin.allTickets}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getTicketUsers = (search: string, user_type: string) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (user_type) params.append("user_type", user_type);

  const url = `${Endpoint.admin.getAllUsers}?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getAuthorStats = () => {
  return HTTP_CLIENT.get(Endpoint.admin.authorStats);
};

const getPublisherStats = () => {
  return HTTP_CLIENT.get(Endpoint.admin.publisherStats);
};

const createBookReviewerService = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.admin.createBookReviewer, params);
};

const getTicketsService = (id: number) => {
  return HTTP_CLIENT.get(Endpoint.admin.getTickets + id.toString());
};

const getConversationService = (id: number) => {
  return HTTP_CLIENT.get(Endpoint.admin.getConversation + id.toString());
};

const closeTicketService = (id: number) => {
  return HTTP_CLIENT.post(Endpoint.admin.closeTicket + id.toString());
};

const getAuthorDetails = (id: any) => {
  return HTTP_CLIENT.get(Endpoint.admin.getAuthorDetails + id);
};

const getAuthorBooksService = (
  id: any,
  search: any,
  activePage: any,
  itemsPerPage: number
) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (activePage) params.append("page", activePage.toString());
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());
  const url = `${
    Endpoint.admin.getAuthorDetails + id + "/books"
  }?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const getBookReviewerBooksService = (
  id: any,
  search: any,
  activePage: any,
  status: any,
  itemsPerPage: number
) => {
  console.log("Items per page--", itemsPerPage);
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (status) params.append("status", status);
  if (activePage) params.append("page", activePage.toString());
  if (itemsPerPage) params.append("per_page", itemsPerPage.toString());
  const url = `${
    Endpoint.admin.getBookReviewerBooks + id + "/books"
  }?${params.toString()}`;
  return HTTP_CLIENT.get(url);
};

const createMOUService = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.admin.createMOU, params);
};

const updateMOUService = (params: {}) => {
  return HTTP_CLIENT.post(Endpoint.admin.updateMOU, params);
};

const deleteMOUService = (id: any) => {
  return HTTP_CLIENT.post(Endpoint.admin.deleteMOU + id);
};

const assignToHeadService = (id: any) => {
  return HTTP_CLIENT.post(Endpoint.admin.assignToHead + id);
};

const allMouService = (id: any) => {
  return HTTP_CLIENT.get(Endpoint.admin.getAllMOUs + id);
};

const getMOUDetails = () => {
  return HTTP_CLIENT.get(Endpoint.admin.getMOUDetail);
};

const blockReviewer = (id: any) => {
  return HTTP_CLIENT.post(Endpoint.admin.blockReviewer + id);
};

const unBlockReviewer = (id: any) => {
  return HTTP_CLIENT.post(Endpoint.admin.unblockReviewer + id);
};

// School Services

const getAllSchoolAccounts = (resp: any) => {
  const params = new URLSearchParams();
  if (resp?.selectedFilter || resp?.selectedFilter === 0)
    params.append("status", resp?.selectedFilter.toString());
  if (resp?.search) params.append("search", resp?.search.toString());
  if (resp?.itemsPerPage)
    params.append("per_page", resp?.itemsPerPage.toString());
  if (resp?.activePage) params.append("page", resp?.activePage.toString());
  return HTTP_CLIENT.get(`${Endpoint.admin.getAllSchoolAccounts}?${params}`);
};

const editLimit = (resp: any) => {
  let formBody = new FormData();
  Object.keys(resp).forEach((key) => {
    formBody.append(key, resp[key]);
  });
  return HTTP_CLIENT.post(Endpoint.admin.changeLimit, formBody);
};

const approveSchool = (schoolId: any) => {
  return HTTP_CLIENT.post(`${Endpoint.admin.approveSchool}/${schoolId}`);
};

const rejectSchool = (schoolId: any) => {
  return HTTP_CLIENT.post(`${Endpoint.admin.rejectSchool}/${schoolId}`);
};

const schoolStats = () => {
  return HTTP_CLIENT.get(Endpoint.admin.schoolStats);
};

const blockSchool = (schoolId: number) => {
  return HTTP_CLIENT.post(`${Endpoint.admin.blockSchool}/${schoolId}`);
};

const unBlockSchool = (schoolId: number) => {
  return HTTP_CLIENT.post(`${Endpoint.admin.unBlockSchool}/${schoolId}`);
};

export {
  getDashboardData,
  getUsersServices,
  getBookReviewerStats,
  getAllBookReviewersService,
  bookReviewerDetails,
  getUsersService,
  createTicketService,
  addCommentService,
  allTicketsService,
  getAuthorStats,
  getPublisherStats,
  createBookReviewerService,
  getTicketsService,
  getConversationService,
  closeTicketService,
  getAuthorDetails,
  createMOUService,
  updateMOUService,
  deleteMOUService,
  assignToHeadService,
  allMouService,
  getMOUDetails,
  getPublisherDataService,
  getPublisherBooksService,
  getAuthorBooksService,
  getBookReviewerBooksService,
  blockReviewer,
  unBlockReviewer,
  getAllSchoolAccounts,
  editLimit,
  approveSchool,
  rejectSchool,
  getTicketUsers,
  schoolStats,
  blockSchool,
  unBlockSchool,
  createTicketAndCommnetServiceByAdmin,
};
