import { Avatar, Box, Rating, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { routeConstant } from "shared/routes/routesConstant";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import DashboardSeries from "../../shared/components/dashboardSeries";

function SeriesHOD() {
  useBreadcrumbs({
    title: "Series",
    path: routeConstant.series.path,
  });

  return (
      <DashboardSeries />
  );
}

export default SeriesHOD;
