import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Paper,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import styles from "./style.module.scss";
import BookInfoCard from "shared/components/bookInfoCard";
import CustomButton from "shared/components/customButton";
import AssignReviewer from "shared/modals/assignReviewer";
import Confirmation from "shared/modals/confirmation";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import {getSeriesDetailsService,
} from "shared/services/general";
import { toastMessage } from "shared/components/toast";
import { options, initialForm } from "shared/utils/constants";
import Section from "shared/components/reviewSection";
import { useParams } from "react-router-dom";
import {
  approveBookService,
  changeBookStatusService, changeSeriesStatusService,
  publishBookService,
  recentRevisionByHOD, recentSeriesRevisionByHOD,
  sendBackRevisionService, sendBackSeriesRevisionService,
} from "shared/services/hodService";
import RejectBook from "shared/modals/rejectBook";
import { routeConstant } from "shared/routes/routesConstant";
import SendToRevision from "shared/modals/sendForRevision";
import useBreadcrumbs from "shared/customHooks/useBreadCrumbs";
import SeriesInfoCard from "../../shared/components/seriesInfoCard";
import RejectSeries from "../../shared/modals/rejectSeries";
import SeriesAssignReviewer from "../../shared/modals/seriesAssignReviewer";

function ReviewSeriesByHOD() {
  const [openReviewer, setOpenReviewer] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [confirmCheck, setConfirmCheck] = useState<any>(null);
  const [openReject, setOpenReject] = useState<boolean>(false);
  const [formData, setFormData] = useState<Record<string, any>>(
    initialForm?.intial_series_review
  );
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>() as { id: string };
  const [seriesDetail, setSeriesDetail] = useState<any>(null);
  const [seriesDetailLoader, setSeriesDetailLoader] = useState<boolean>(false);
  const [openRevision, setOpenRevision] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const handleReviewOpen = () => {
    if (!handleValidation()) {
      setOpenReviewer(true);
    }
  };

  const handleOpenRevision = () => {
    setOpenRevision(true);
  };

  const handleCloseRevision = () => {
    setOpenRevision(false);
  };

  const handleCloseReviewer = () => {
    setOpenReviewer(false);
  };

  const handleConfirmClose = () => {
    setOpenConfirm(false);
    setConfirmCheck(null);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const getSeriesDetails = () => {
    setSeriesDetailLoader(true);
    getSeriesDetailsService(id)
      .then(({ data }) => {
        setSeriesDetail(data?.data);
        setSeriesDetailLoader(false);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
        setSeriesDetailLoader(false);
      });
  };

  useEffect(() => {
    getSeriesDetails();
    // eslint-disable-next-line
  }, [id]);

  const changeStatus = () => {
    changeSeriesStatusService({ series_id: id, status: 1 })
      .then(({ data }) => {})
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    changeStatus();

    // eslint-disable-next-line
  }, []);

  const handleActions = () => {
    let resp = {
      book_id: seriesDetail?.id,
      rating: 6,
      feedback: {
        intial_review: formData,
        detailed_review: null,
        reject_book: null,
      },
    };

    const service =
      confirmCheck?.check === "approve"
        ? approveBookService
        : confirmCheck?.check === "revision"
        ? sendBackRevisionService
        : confirmCheck?.check === "publish"
        ? publishBookService
        : null;

    service?.(resp)
      .then(({ data }) => {
        toastMessage("success", data?.message);
        navigate(routeConstant.series.path);
      })
      .catch((err) => {
        toastMessage("error", err?.response?.data?.message);
        console.log("err", err?.response?.data?.message);
      });
  };

  const handleValidation = () => {
    let hasError = false;

    Object.values(formData).forEach((item) => {
      if (item.option === 0) {
        if (item.type === "checklist") {
          const hasTrueValue = item.options.some(
            (itm: any) => itm.value === true
          );

          if (hasTrueValue) {
            const isSelectedOther = item.options.find(
              (itm: any) => itm.value === true && itm.name === "Others"
            );

            if (isSelectedOther?.comment === "") {
              toastMessage("error", `Please type something in ${item.label}`);
              hasError = true;
            }
          }

          if (!hasTrueValue) {
            toastMessage("error", `Please select something in ${item.label}`);
            hasError = true;
          }
        }

        if (item.type === "dropdown" && item.selected === null) {
          toastMessage("error", `Please select something in ${item.label}`);
          hasError = true;
        }

        if (item.type === "text" && item.comment === "") {
          toastMessage("error", `Please type something in ${item.label}`);
          hasError = true;
        }
      }
    });

    return hasError;
  };

  const handleRejection = () => {
    if (!handleValidation()) {
      setOpenReject(true);
    }
  };

  const handleSendRevision = (form: any) => {
    if (!handleValidation()) {
      let resp = {
        series_id: seriesDetail?.id,
        feedback: {
          intial_review: form,
          detailed_review: null,
          reject_book: null,
        },
      };

      sendBackSeriesRevisionService(resp)
        .then(({ data }) => {
          toastMessage("success", data?.message);
          navigate(routeConstant.dashboard.path);
        })
        .catch((err) => {
          toastMessage("error", err?.response?.data?.message);
          console.log("err", err?.response?.data?.message);
        });
    }
  };

  const handleCheckHistory = () => {
    recentSeriesRevisionByHOD(id)
      .then(({ data }) => {
        setFormData(
          data?.data?.data?.intial_review
            ? data?.data?.data?.intial_review
            : initialForm?.intial_series_review
        );
        setEdit(data?.data?.data?.intial_review ? true : false);
      })
      .catch((err) => {
        console.log("error", err?.response?.data?.message);
        console.log("error", err?.response?.data?.message);
      });
  };

  useEffect(() => {
    handleCheckHistory();
    // eslint-disable-next-line
  }, [id]);

  useBreadcrumbs({
    title: "Initial Review",
    path: routeConstant.reviewByHOD.path.replace(":id", id),
  }, true);

  return (
    <Card className={styles.card}>
      <CardContent className={styles.cardContent}>
        <SeriesInfoCard series={seriesDetail} loader={seriesDetailLoader} />
        {!seriesDetailLoader ? (
          <>
            {" "}
            {edit ? (
              <div style={{ marginTop: "10px" }}>
                <div className={styles.titleContainer}>
                  <div className={styles.titleTable}>Review</div>
                  <CustomButton
                    text="Edit"
                    handleClick={() => setEdit(false)}
                    buttonStyle={styles.btnCustom}
                  />
                </div>
                <TableContainer component={Paper} className={styles.table}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow className={styles.tableHead}>
                        <TableCell>Parameters</TableCell>
                        <TableCell>Response</TableCell>
                        <TableCell>Comments</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(formData)
                        .map((key) => formData[key])
                        ?.map((item: any) => {
                          return (
                            <TableRow>
                              <TableCell>{item?.label}</TableCell>
                              <TableCell>
                                {item?.type === "rating" ? (
                                  <Rating
                                    name="simple-controlled"
                                    value={item?.value}
                                    readOnly
                                  />
                                ) : Number(item?.option) === 0 ? (
                                  "No"
                                ) : (
                                  "Yes"
                                )}
                              </TableCell>
                              <TableCell>
                                {item?.type === "checklist" &&
                                  item?.options?.map((itm: any) => {
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {itm?.value ? (
                                          <>
                                            <CheckIcon
                                              style={{ fill: "#9A469B" }}
                                            />{" "}
                                            <span
                                              style={{ marginLeft: "10px" }}
                                            >
                                              {itm?.name === "Others"
                                                ? itm?.name +
                                                  ": " +
                                                  itm?.comment
                                                : itm?.name}
                                            </span>
                                          </>
                                        ) : null}
                                      </div>
                                    );
                                  })}

                                {item?.type === "dropdown" &&
                                  item?.selected && (
                                    <div>
                                      Selected: {item?.selected?.name}{" "}
                                      {item?.selected?.text}
                                    </div>
                                  )}

                                {item?.type === "rating" && (
                                  <div>{item?.comment}</div>
                                )}
                                {item?.type === "text" && (
                                  <div>{item?.comment}</div>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : (
              <Grid spacing={2} container className={styles.selectionContainer}>
                <Grid xs={12} sm={6} padding={"20px 0px"}>
                  <Section
                    options={options}
                    label="Series Name"
                    state={formData}
                    setState={setFormData}
                    name="name"
                  />
                </Grid>

                <Grid xs={12} sm={6} padding={"20px 0px"}>
                  <Section
                    options={options}
                    label="Series Description"
                    state={formData}
                    setState={setFormData}
                    name="description"
                  />
                </Grid>
              </Grid>
            )}
            <div className={styles.btnContainer}>
              {/* <CustomButton
                text="Approve"
                buttonStyle={styles.btnCustom}
                handleClick={() =>
                  handleConfirmOpen(
                    "Are you sure, you want to approve?",
                    "approve"
                  )
                }
              /> */}
              <CustomButton
                text="Reject"
                buttonStyle={styles.btnCustom}
                handleClick={
                  () => handleRejection()
                  // () => handleValidation()
                }
              />
              {/* <CustomButton
                text="Publish"
                buttonStyle={styles.btnCustom}
                handleClick={() =>
                  handleConfirmOpen(
                    "Are you sure, you want to publish this book?",
                    "publish"
                  )
                }
              /> */}

              <CustomButton
                text="Send back for revision"
                buttonStyle={styles.btnInsideOut}
                handleClick={() =>
                  // handleConfirmOpen(
                  //   "Are you sure, you want to send this to revision?",
                  //   "revision"
                  // )
                  handleOpenRevision()
                }
              />
              <CustomButton
                text="Assign to reviewer"
                buttonStyle={styles.btnInsideOut}
                handleClick={handleReviewOpen}
              />
            </div>
          </>
        ) : null}
      </CardContent>

      <SendToRevision
        open={openRevision}
        handleClose={handleCloseRevision}
        formData={formData}
        handleSendRevision={handleSendRevision}
      />
      <SeriesAssignReviewer
        open={openReviewer}
        handleClose={handleCloseReviewer}
        initialDetail={formData}
        selectedSeries={seriesDetail}
      />
      <RejectSeries
        open={openReject}
        handleClose={() => setOpenReject(false)}
        selectedBook={seriesDetail}
        feedback={formData}
        isRating
      />
      <Confirmation
        open={openConfirm}
        handleClose={handleConfirmClose}
        text={confirmCheck?.label}
        handleClick={handleActions}
      />
    </Card>
  );
}

export default ReviewSeriesByHOD;
